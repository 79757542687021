"use strict";

import { getStyleValueByNumber } from "./_common";
import { BIKE_BASE_Y_BY_GRID_SPACE, GEO_SIZE, GRID_SPACE } from "./_const";

export function changeScale(setType) {
  // changeBaseは位置の変更だけ、スケールの変更はここで実施
  const setScale = (scaleType) => {
    // dispのscaleはresizeで実施される
    const scale = 1.3;
    const map1 = (() => {
      if (scaleType === "in") {
        return {
          top: -GRID_SPACE * 4 * scale,
          left: -GRID_SPACE * 2 * scale,
          scale,
        };
      } else {
        return { top: 0, left: 0, scale: 1 };
      }
    })();
    const getOffsetY = (t) => {
      if (scaleType === "in") {
        const cnvs = t.querySelector(
          `canvas[data-class='tireSizeRear'][data-z-index='TIRE']`
        );
        const tire = Number(cnvs.height);
        return (tire / 2) * scale;
      } else {
        return 0;
      }
    };
    const wrpBikes = document.querySelectorAll(`div[data-class='wrapperBike']`);
    const q1 = "canvas[data-class='wheelBase'][data-z-index='LINE']";
    const q2 = "canvas[data-class='wheelBaseHighlight']";
    wrpBikes.forEach((t) => {
      const ofsY = getOffsetY(t);
      t.style.transform = `scale(${map1.scale})`;
      t.style.top = `${map1.top + ofsY}px`;
      t.style.left = `${map1.left}px`;
      const wbs = t.querySelectorAll(`${q1},${q2}`);
      const wbGrid = t.querySelector(
        "canvas[data-class='wheelBase'][data-z-index='GRID']"
      );
      const wbGridTop = getStyleValueByNumber({ target: wbGrid, item: "top" });
      wbs.forEach((t2) => {
        if (scaleType === "in") {
          t2.style.top = `${wbGridTop + GRID_SPACE}px`;
        } else {
          t2.style.top = `${
            GEO_SIZE.height - BIKE_BASE_Y_BY_GRID_SPACE - t2.height / 2
          }px`;
        }
      });
    });

    const map2 = (() => {
      if (scaleType === "in") {
        return {
          top: (-GRID_SPACE / 2) * scale,
          left: (-GRID_SPACE / 2) * scale,
          scale,
        };
      } else {
        return { top: -GRID_SPACE / 2, left: -GRID_SPACE / 2, scale: 1 };
      }
    })();
    const gw = document.getElementById("gridWhole");
    gw.style.transform = `scale(${map2.scale})`;
    gw.style.top = `${map2.top}px`;
    gw.style.left = `${map2.left}px`;

    const map3 = (() => {
      if (scaleType === "in") {
        return {
          top: GRID_SPACE * 6.5 * scale,
          left: (-GRID_SPACE / 2) * scale,
          scale,
        };
      } else {
        return { top: GRID_SPACE * 10.5, left: -GRID_SPACE / 2, scale: 1 };
      }
    })();
    const gridX = document.getElementById("gridBaseX");
    gridX.style.transform = `scale(${map3.scale})`;
    gridX.style.top = `${map3.top}px`;
    gridX.style.left = `${map3.left}px`;

    const map4 = (() => {
      if (scaleType === "in") {
        return {
          top: 0,
          left: GRID_SPACE * 7.5 * scale,
          scale,
        };
      } else {
        return { top: 0, left: GRID_SPACE * 9.5, scale: 1 };
      }
    })();
    const gridY = document.getElementById("gridBaseY");
    gridY.style.transform = `scale(${map4.scale})`;
    gridY.style.top = `${map4.top}px`;
    gridY.style.left = `${map4.left}px`;
  };

  // process
  const disp = document.getElementById("display");
  if (setType === "change") {
    if (disp.dataset.scale === "out") {
      disp.dataset.scale = "in";
    } else {
      disp.dataset.scale = "out";
    }
  }
  setScale(disp.dataset.scale);
}
