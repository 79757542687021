"use strict";
import { getYears } from "./_common";
import { FONT_COLOR } from "./_const";
import { setActiveClass, setBikeNameToDisp, setWordingToDisp } from "./display";
import { setStyleToPageContext } from "./page";
import { execGetBikeData } from "./php";
import { setBikeNameToYearH } from "./sideMenu";
import { setBikeNameToSimilarOfSideMenu } from "./similar";
import { setSizePagination } from "./sizePagination";
import { setBikeNameToThNameHeader, setBikeNameToThName } from "./sizeTable";

export const setWordingToDataset = (wording) => {
  const ary = [];
  for (let i = 0; i < wording.length; i++) {
    ary.push(wording[i].id);
    const ts = document.querySelectorAll(
      `[data-wording-id='${wording[i].id}']`
    );
    if (ts.length === 0) continue;
    ts.forEach((t) => {
      t.dataset.wordingJpn = wording[i].jpn;
      t.dataset.wordingEng = wording[i].eng;
    });
  }
  document.getElementById("display").dataset.wordingIds = ary;
};

export const setWordingAfterToDataset = (wording) => {
  const wordingTd = wording.filter((t) => t.class === "td");
  document.getElementById("sizeTable").dataset.wordingAfter =
    JSON.stringify(wordingTd);

  const wordingContentBox = wording.filter((t) => t.class === "cb");
  document.getElementById("contentBox").dataset.wordingAfter =
    JSON.stringify(wordingContentBox);
};

export const setWordingAfter = () => {
  const lang = document.getElementById("display").dataset.lang;
  const ids = JSON.parse(
    document.getElementById("sizeTable").dataset.wordingAfter
  );
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i].id;
    const wording = ids[i][lang];
    const ts = document.querySelectorAll(`[data-wording-id='${id}']`);
    if (ts.length === 0) continue;
    ts.forEach((t) => (t.textContent = wording));
  }
};

export const getWordingAfterBy = (target, id) => {
  const lang = document.getElementById("display").dataset.lang;
  const wording = JSON.parse(target.dataset.wordingAfter);
  const t = wording.find((t) => t.id === id);
  if (!t) return null;

  return t[lang];
};

export const setWording = () => {
  const lang = document.getElementById("display").dataset.lang;
  const map = { eng: "wordingEng", jpn: "wordingJpn" };
  const wording = map[lang];
  const ids = document.getElementById("display").dataset.wordingIds.split(",");
  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const ts = document.querySelectorAll(`[data-wording-id='${id}']`);
    if (ts.length === 0) continue;
    ts.forEach((t) => {
      if (id === "term2" || id === "contact2" || id === "tips2") {
        t.innerText = t.dataset[wording];
      } else {
        t.textContent = t.dataset[wording];
      }
    });
  }

  const yearHs = document.querySelectorAll(`h3[data-class='yearH']`);
  for (let i = 0; i < yearHs.length; i++) {
    const mkr = getWordingNameByAll(yearHs[i].dataset.mkr);
    const mdl = getWordingNameByAll(yearHs[i].dataset.mdl);
    if (!mkr || !mdl) continue;
    const year = getYears(yearHs[i].dataset.year);
    yearHs[i].textContent = `${mkr[wording]} ${mdl[wording]} ${year}`;
  }

  // setWordingToDisp();
  setBikeNameToDisp();
  setBikeNameToThNameHeader();
  setBikeNameToThName();
  setBikeNameToYearH();
  setBikeNameToSimilarOfSideMenu();

  setSizePagination();

  setStyleToPageContext();
};

export const getWordingNameByAll = (name) => {
  const t = document.querySelector(`[data-wording-id='name${name}']`);
  if (!t) return null;
  if (!t.dataset.wordingEng) return null;
  return {
    eng: t.dataset.wordingEng,
    jpn: t.dataset.wordingJpn,
  };
};

export const getWordingName = (name) => {
  const lang = document.getElementById("display").dataset.lang;
  const map = getWordingNameByAll(name);
  if (!map) return null;
  return map[lang];
};

export const setColorToBtnWording = (t) => {
  const ts = getTargets(t);
  ts.set.forEach((t) => {
    setActiveClass(t, "add", "remove");
    t.style.color = "";
  });
  ts.init.forEach((t) => {
    setActiveClass(t, "remove", "add");
    t.style.color = FONT_COLOR;
  });

  function getTargets(t) {
    const eng = document.getElementById("btnWordingToEng");
    const jpn = document.getElementById("btnWordingToJpn");
    const engNavi = document.getElementById("btnWordingToEngNavi");
    const jpnNavi = document.getElementById("btnWordingToJpnNavi");
    if (t.id === "btnWordingToEng" || t.id === "btnWordingToEngNavi") {
      return { set: [eng, engNavi], init: [jpn, jpnNavi] };
    } else if (t.id === "btnWordingToJpn" || t.id === "btnWordingToJpnNavi") {
      return { set: [jpn, jpnNavi], init: [eng, engNavi] };
    }
  }
};

export const setLanguageTypeToDataset = (t) => {
  const disp = document.getElementById("display");
  if (t.id === "btnWordingToEng" || t.id === "btnWordingToEngNavi") {
    disp.dataset.lang = "eng";
  } else if (t.id === "btnWordingToJpn" || t.id === "btnWordingToJpnNavi") {
    disp.dataset.lang = "jpn";
  }
};
