"use strict";
import {
  createIncrementObject,
  getStyleValueByNumber,
  getDispTypeByWGTT,
  getScaledValue,
  getYears,
  replaceName,
} from "./_common";
import { isVisiblePartsValue } from "./_constDrawPartsValue";
import { GEOMETRY_SPEC, getCompareGeometries } from "./_constGeometry";
import { mathRoundSetDecimalPlace } from "./_math";
import { execIsAngle } from "./bike";
import {
  FONT_COLOR,
  FONT_COLOR_GEOMETRY,
  GEO_SIZE,
  NAMES,
  ZINDEX,
} from "./_const";
import { updateSelectedBikeCountOfPHP } from "./php";
import { getContentHeightOfPage } from "./resize";
import { createSvgOfTagByOutline, createSvgOfTagBySolid } from "./svg";
import { setCompareTagOfTh, setCompareTagOfDisp } from "./compare";
import {
  duplicateImgDisp,
  initImgDisp,
  removeImgDisp,
  setImgDisp,
} from "./display";
import { getSimilar } from "./similar";
import {
  getWordingName,
  getWordingNameByAll,
  setWording,
  setWordingAfter,
} from "./wording";

const TD_COLOR = {
  NONE: "",
  SELECTED: "rgba(20, 250, 150, 0.1)",
};

export const SIZE_TABLE = {
  // thWidth: GEO_SIZE.width / 4, getScaledValueで小数点の誤差が出る
  tdWidth: (GEO_SIZE.width / 3) * 0.75,
  cellHeight: 80,
  font: 25,
  startNumberOfRowCount: 1,
  maximumShowColumns: 3,
  TH_P_MARGIN: 10,
};

const isDecimalParts = (name) => {
  return (
    name === "headTubeAngle" || name === "seatTubeAngle" || name === "seatPost"
  );
};

// styleの設定はexecOnResizeDisplayAndTableでおこなう
export function createSizeTable() {
  console.log(`createSizeTable`);
  const geometryItems = getBikeDataOfGeometry();
  const visibleRows = createCounterOfVisibleRows();
  const f0 = document.createDocumentFragment();
  geometryItems.forEach((geometryItem) => {
    createRowHeaderToSizeTable(geometryItem);
  });

  const thead = document.createElement("thead");
  const names = ["compare", "img", "name"];
  names.forEach((name) => {
    const th = document.createElement("th");
    th.dataset.class = name;
    th.dataset.colIndex = "header";
    setClassByHeaderRowCol(th);
    th.style.zIndex = ZINDEX.TH;
    if (name === "compare") th.dataset.wordingId = "stCompare";

    const tr = document.createElement("tr");
    tr.dataset.class = name;
    tr.style.position = "sticky";
    tr.style.top = "0px";
    tr.style.zIndex = ZINDEX.TR_SIZE;

    tr.appendChild(th);
    thead.appendChild(tr);
  });

  const tb = document.createElement("tbody");
  const st = document.getElementById("sizeTable");
  tb.appendChild(f0);
  st.appendChild(thead);
  st.appendChild(tb);

  function getBikeDataOfGeometry() {
    return Object.entries(GEOMETRY_SPEC);
  }
  function createCounterOfVisibleRows() {
    return createIncrementObject(SIZE_TABLE.startNumberOfRowCount);
  }
  function createRowHeaderToSizeTable(geometryItem) {
    const dataName = geometryItem[0];
    const item = geometryItem[1];
    const tr = createRow();
    const th = createHeader(tr);
    if (isDisplayTableRow(item.display)) {
      visibleRows.addCount(1);
      // setBackGroundColorToRowHeader(tr, th);
    } else {
      tr.style.display = "none";
      th.style.display = "none";
    }

    function createRow() {
      const tr = document.createElement("tr");
      tr.dataset.class = dataName;
      // tr.style.borderTopWidth = "1px";
      // tr.style.borderTopStyle = "solid";
      // tr.classList.add("border-neutral-100");
      f0.appendChild(tr);
      return tr;
    }
    function createHeader(tr) {
      const th = document.createElement("th");
      th.dataset.class = dataName;
      th.dataset.colIndex = "header";
      th.dataset.wordingId = GEOMETRY_SPEC[dataName].wordingId;
      setClassByHeaderRow(th);
      // setClassByBorder(th);
      if (isVisiblePartsValue(dataName)) {
        setClassByHoverLight(th);
        th.style.color = FONT_COLOR;
      }
      th.dataset.originalValue = item.fullName;
      th.style.zIndex = ZINDEX.TH;
      th.scope = "row";
      // th.textContent = item.shortName;
      tr.appendChild(th);
      return th;
    }
    function isDisplayTableRow(status) {
      return status === "block";
    }
  }
  // function setBackGroundColorToRowHeader(tr, th) {
  //   const cnt = visibleRows.getCount() % 2;
  //   // const map = { 0: "bg-red-400", 1: "bg-green-400" };
  //   const map = { 0: "bg-white", 1: "bg-neutral-100" };
  //   const color = map[cnt];
  //   tr.classList.add(color);
  //   th.classList.add(color);
  // }
}

export const setBackGroundColorToRowHeader = () => {
  const color1 = "bg-neutral-100";
  const color2 = "bg-white";
  const trs = document.querySelectorAll(`tr`);

  // init
  trs.forEach((tr) => {
    const th = tr.querySelector(`th[data-col-index='header']`);
    tr.classList.remove(color1);
    tr.classList.remove(color2);
    th.classList.remove(color1);
    th.classList.remove(color2);
  });

  // set
  let cnt = 0;
  for (let i = 0; i < trs.length; i++) {
    const tr = trs[i];
    if (tr.style.display === "none") continue;

    const th = tr.querySelector(`th[data-col-index='header']`);
    const color = getColor(cnt);
    tr.classList.add(color);
    th.classList.add(color);
    cnt++;
  }

  function getColor(num) {
    if (num === 0) {
      return color1;
    } else {
      if (num % 2 === 1) {
        return color2;
      } else {
        return color1;
      }
    }
  }
};

export function setClassByBorder(t) {
  t.classList.add("border-solid");
  t.classList.add("border");
  t.classList.add("border-neutral-300");
}
export function setClassByHoverLight(t) {
  t.classList.add("hover:border-neutral-600");
  t.classList.add("hover:text-neutral-600");
  t.classList.add("cursor-pointer");
}
export function setClassByHoverDark(t) {
  t.classList.add("text-neutral-300");
  t.classList.add("hover:border-neutral-800");
  t.classList.add("hover:text-neutral-800");
  t.classList.add("cursor-pointer");
}
export function setClassByHeader(t) {
  t.classList.add("bg-gradient-to-br");
  t.classList.add("from-orange-500");
  t.classList.add("to-pink-500");
  t.classList.add("text-white");
  t.classList.add("font-semibold");
  // t.classList.add("leading-none"); // safariで上下位置がずれる場合がある
}

export function setClassByHeaderRow(t) {
  t.style.color = FONT_COLOR;
  t.classList.add("p-0");
  t.classList.add("sticky");
  t.classList.add("left-0");
  t.classList.add("items-center");
  t.classList.add("text-left");
}

export function setClassByHeaderRowCol(t) {
  t.style.color = FONT_COLOR;
  t.classList.add("p-0");
  t.classList.add("sticky");
  t.classList.add("left-0");
  t.classList.add("items-center");
  t.classList.add("text-left");
}

export function getEventTypeByClickSizeTable(thImg) {
  return "change";
}

export function setStyleToTDTH() {
  const width = getWidth();
  const height = getScaledValue(SIZE_TABLE.cellHeight);
  // heightは下位、widthは上位の要素で指定する
  // 親となるtrに高さを指定すると入れ子の高さがheight=100%にすると機能しない
  // 一番下の階層の子孫となる要素に高さを指定すると先祖要素の高さが同じになる
  document.querySelector(
    `th[data-class='compare']`
  ).style.width = `${width.th}px`;

  const trCompare = document.querySelector(`tr[data-class='compare']`);
  const trImg = document.querySelector(`tr[data-class='img']`);
  const trName = document.querySelector(`tr[data-class='name']`);
  trCompare.style.height = `${height}px`;
  trImg.style.height = `${height * 2}px`;
  trName.style.height = `${height}px`;

  const isCompare = document.getElementById("display").dataset.compareDisp;
  const trCompareHeight = getStyleValueByNumber({
    target: trCompare,
    item: "height",
  });
  const trImgHeight = getStyleValueByNumber({
    target: trImg,
    item: "height",
  });

  const dispType = getDispTypeByWGTT();
  if (dispType === "flex") {
    // trCompare.style.position = "sticky";
    trImg.style.position = "sticky";
    // trName.style.position = "sticky";
    const trImgHeight2 = isCompare === "true" ? trImgHeight : 0;
    trCompare.style.top = `0px`;
    trImg.style.top = `${trCompareHeight}px`;
    trName.style.top = `${trCompareHeight + trImgHeight2}px`;
  } else if (dispType === "block") {
    // trCompare.style.position = "";
    trImg.style.position = "";
    // trName.style.position = "";
    trCompare.style.top = "0px";
    trImg.style.top = "";
    trName.style.top = `${trCompareHeight}px`;
  }

  document.querySelectorAll("th[scope='row']").forEach((t) => {
    // t.style.height = "100%";
    t.style.height = `${height}px`;
    t.style.width = `${width.th}px`;
  });
  document.querySelectorAll("td,th[scope='col']").forEach((t) => {
    // t.style.height = "100%";
    t.style.height = `${height}px`;
    t.style.width = `${width.td}px`;
  });
  document.querySelectorAll("td span").forEach((t) => {
    t.style.width = "100%";
    // t.style.width = `${width.td}px`;
  });
  document.querySelectorAll(`div[data-class='wrpImgTh']`).forEach((t) => {
    t.style.height = `${height * 2}px`;
    t.style.width = `${width.td}px`;
  });

  document
    .querySelectorAll(
      `button[data-class='btnTagOutLine'],button[data-class='btnTagSolid']`
    )
    .forEach((t) => {
      t.style.height = `${height - 4}px`;
      t.style.width = `${height - 4}px`;
    });

  // setHeight(target);
  setPadding();
  setFont();

  function getWidth() {
    const tdWidth = getScaledValue(SIZE_TABLE.tdWidth);
    const geoWidth = getScaledValue(GEO_SIZE.width);
    const thWidth = geoWidth - tdWidth * SIZE_TABLE.maximumShowColumns - 2;
    return {
      th: thWidth,
      td: tdWidth,
    };
  }
  function setFont() {
    const sizeTable = document.getElementById("sizeTable");
    const fontSize = getScaledValue(SIZE_TABLE.font);
    sizeTable.style.fontSize = `${fontSize}px`;
    sizeTable.style.lineHeight = `${fontSize}px`;
  }
  function setPadding() {
    const scaledPadding = getScaledValue(SIZE_TABLE.TH_P_MARGIN);
    document
      .querySelectorAll(
        "th[scope='row'], th[data-class='compare'][data-col-index='header'], th[data-class='name'][data-col-index='header']"
      )
      .forEach((t) => {
        t.style.padding = `${scaledPadding}px`;
      });
  }
}

export function removeSizeTable() {
  const thImgs = document.querySelectorAll(
    `th[data-class='img'][data-compare='false']`
  );
  thImgs.forEach((thImg) => {
    const tds = document.querySelectorAll(
      `th[data-col-index='${thImg.dataset.colIndex}'],td[data-col-index='${thImg.dataset.colIndex}']`
    );
    tds.forEach((t) => t.remove());
  });

  const thImgBase = document.querySelector(
    `th[data-class='img'][data-col-index='header'] div[data-class='wrpImgTh']`
  );
  if (thImgBase) thImgBase.remove();
  const wrpTextTh = document.getElementById("wrpTextTh");
  if (wrpTextTh) wrpTextTh.remove();
}

export const initSizeTable = () => {
  // init display
  const ts = document.querySelectorAll(`th[scope='col'],td`);
  ts.forEach((t) => {
    t.style.display = "none";
  });
  // init color
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImgs = document.querySelectorAll(
    `th[data-class='img'][data-active='false'][data-compare='${isCompare}']`
  );
  thImgs.forEach((thImg) => {
    const query = `[data-col-index='${thImg.dataset.colIndex}']`;
    const thtds = document.querySelectorAll(`th${query},td${query}`);
    thtds.forEach((t) => {
      t.style.backgroundColor = TD_COLOR.NONE;
    });
  });
};

export const setSizeTableBy = (setType, mkr, mdl, year) => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const wrpImgTh = document.querySelector(
    `th[data-class='img'][data-col-index='header'] div[data-class='wrpImgTh']`
  );
  const wrpTextTh = document.getElementById("wrpTextTh");
  const trImg = document.querySelector(`tr[data-class='img']`);
  if (isCompare === "true") {
    if (wrpImgTh) wrpImgTh.style.display = "none";
    if (wrpTextTh) wrpTextTh.style.display = "none";
    trImg.style.display = "";
  } else {
    if (wrpImgTh) wrpImgTh.style.display = "";
    if (wrpTextTh) wrpTextTh.style.display = "";
    trImg.style.display = "none";
  }

  // show all item
  const thImgs = getThImgs();
  thImgs.forEach((thImg) => {
    const query = `[data-col-index='${thImg.dataset.colIndex}']`;
    const thtds = document.querySelectorAll(`th${query},td${query}`);
    thtds.forEach((t) => (t.style.display = ""));
  });

  // set color to selected
  const selectedThImg = document.querySelector(
    `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
  );
  if (!selectedThImg) return;
  const query = `[data-col-index='${selectedThImg.dataset.colIndex}']`;
  const thtds = document.querySelectorAll(`th${query},td${query}`);
  thtds.forEach((t) => {
    t.style.backgroundColor = TD_COLOR.SELECTED;
  });

  function getThImgs() {
    if (setType === "size") {
      return document.querySelectorAll(
        `th[data-class='img'][data-mkr='${mkr}'][data-mdl='${mdl}'][data-years='${year}'][data-compare='false']`
      );
    } else {
      return document.querySelectorAll(
        `th[data-class='img'][data-compare='true']`
      );
    }
  }
};

export function execGetSelectedIndexByTr() {
  const selectedTr = getSelectedTr();
  if (!selectedTr) {
    return null;
  }
  return Number(selectedTr.dataset.row);

  function getSelectedTr() {
    const className = document.getElementById("display").dataset.highlightParts;
    return document.querySelector(`tr[data-class='${className}']`);
  }
}

export function setBikeDataToSizeTable(setType, wrpImg, bikes) {
  setBikeData(bikes);
  updateSelectedBikeCountOfPHP();
  setStyleToTDTH();
  setCompareTagOfTh();
  setCompareTagOfDisp();
  // setWordingToThNameHeader();
  setWordingToThName();
  setWording();
  setWordingAfter();

  function setBikeData(bikes) {
    bikes.forEach((bike) => {
      setSize(bike);
    });

    function setSize(bike) {
      for (const dataType in bike) {
        for (const dataName in bike[dataType]) {
          setGeometry(dataType, dataName);
        }
      }
      // 別で追加
      setGeometry("info", "source");
      setGeometry("info", "similar");
      setRowHeader();

      function setGeometry(dataType, dataName) {
        const keys = Object.keys(GEOMETRY_SPEC);
        if (!keys.includes(dataName)) return;
        const value = getValue();
        const td = createTd();
        const d1 = document.createElement("div"); // value
        d1.style.textAlign = "center";
        if (dataName === "source") {
          const btn = document.createElement("button");
          btn.dataset.class = "sourceBtn";
          btn.dataset.wordingId = "tdSource";
          btn.classList.add("hover:text-emerald-400");
          d1.appendChild(btn);
        } else if (dataName === "similar") {
          const similar = getSimilar(
            bike.info.mkr,
            bike.info.mdl,
            bike.info.year,
            bike.info.size
          );
          const btn = document.createElement("button");
          btn.dataset.class = "similarBtn";
          if (similar.length > 0) {
            btn.dataset.wordingId = "tdSimilar";
            btn.dataset.similar3 = similar;
          }
          btn.classList.add("hover:text-emerald-500");
          d1.appendChild(btn);
        } else {
          d1.textContent = value;
        }
        // d1.style.whiteSpace = "nowrap";
        // d1.style.overflow = "hidden";
        // d1.style.textOverflow = "ellipsis";
        d1.classList.add("truncate");
        const d2 = document.createElement("div"); // compare
        d2.style.textAlign = "center";
        d2.style.opacity = 0.5;
        td.appendChild(d1);
        td.appendChild(d2);

        function getValue() {
          const tempValue = getTempValue();
          // "",null,0はfalseになる
          if (!tempValue && tempValue !== 0) {
            return null;
          }
          if (isNaN(tempValue)) {
            return String(tempValue);
          }
          const decimal = getDecimal();
          return mathRoundSetDecimalPlace(tempValue, decimal);

          function getTempValue() {
            const parts = bike[dataType][dataName];
            if (dataType === "info") {
              return parts;
            }
            if (dataType === "value") {
              return parts.ori.val;
            }
            if (execIsAngle(dataName)) {
              return parts.ori.agl;
            }
            return parts.ori.len;
          }
          function getDecimal() {
            if (isDecimalParts(dataName)) {
              return 1;
            }
            return 0;
          }
        }
        function createTd() {
          const tableRow = document.querySelector(
            `tr[data-class='${dataName}']`
          );
          const td = tableRow.insertCell();
          td.dataset.class = dataName;
          td.dataset.sourceVal = getSourceOfValue();
          td.dataset.colIndex = bike.info.colIndex;
          if (td.dataset.sourceVal === "geometry") {
            td.style.color = FONT_COLOR;
          } else {
            td.classList.add(FONT_COLOR_GEOMETRY[td.dataset.sourceVal]);
          }
          setClassByHoverLight(td);
          // setClassByBorder(td);
          // td.style.display = "none";
          return td;

          function getSourceOfValue() {
            if (bike.info.calculated.includes(dataName)) {
              return "calculated";
            } else if (bike.info.referenced.includes(dataName)) {
              return "referenced";
            } else {
              return "geometry";
            }
          }
        }
      }
      function setRowHeader() {
        const createTh = () => {
          const th = document.createElement("th");
          th.dataset.colIndex = bike.info.colIndex;
          th.scope = "col";
          // th.classList.add("grid");
          // th.classList.add("place-items-center");
          // setClassByHeader(th);
          // setClassByHoverLight(th);
          setClassByHoverLight(th);
          // setClassByBorder(th);
          th.style.color = FONT_COLOR;
          return th;
        };

        const thCompare = createTh();
        thCompare.dataset.class = "compare";
        const btnTagOutLine = document.createElement("button");
        btnTagOutLine.dataset.class = "btnTagOutLine";
        btnTagOutLine.classList.add("stroke-neutral-300");
        btnTagOutLine.classList.add("hover:stroke-neutral-800");
        btnTagOutLine.classList.add("hover:text-neutral-800");
        btnTagOutLine.classList.add("stroke-neutral-300");
        btnTagOutLine.classList.add("m-auto");
        btnTagOutLine.type = "button";
        const svgTagOutLine = createSvgOfTagByOutline();
        btnTagOutLine.appendChild(svgTagOutLine);
        thCompare.appendChild(btnTagOutLine);
        const btnTagSolid = document.createElement("button");
        btnTagSolid.dataset.class = "btnTagSolid";
        btnTagSolid.classList.add("hover:fill-neutral-700");
        btnTagSolid.type = "button";
        btnTagSolid.style.display = "none";
        const svgTagSolid = createSvgOfTagBySolid();
        btnTagSolid.appendChild(svgTagSolid);
        thCompare.appendChild(btnTagSolid);
        const trCompare = document.querySelector(`tr[data-class='compare']`);
        trCompare.appendChild(thCompare);

        const thImg = createTh();
        thImg.dataset.class = "img";
        thImg.dataset.mkr = bike.info.mkr;
        thImg.dataset.mdl = bike.info.mdl;
        thImg.dataset.years = bike.info.year;
        thImg.dataset.size = bike.info.size;
        if (setType === "size") {
          thImg.dataset.year = wrpImg.parentElement.dataset.year;
          thImg.dataset.color = wrpImg.parentElement.dataset.color;
        } else if (setType === "compare") {
          thImg.dataset.year = wrpImg.dataset.year;
          thImg.dataset.color = wrpImg.dataset.color;
        }
        thImg.dataset.active = "false";
        thImg.dataset.compare = "false";
        thImg.dataset.bike = JSON.stringify(bike);
        if (setType === "compare") {
          const cloneWrpImg = wrpImg.cloneNode(true);
          cloneWrpImg.dataset.class = "wrpImgTh";
          cloneWrpImg.dataset.colIndex = bike.info.colIndex;
          cloneWrpImg.style.margin = "0 auto";
          cloneWrpImg.style.display = ""; // サイズ未選択だとdisplay:noneのまま
          thImg.appendChild(cloneWrpImg);
        }
        const trImg = document.querySelector(`tr[data-class='img']`);
        trImg.appendChild(thImg);

        const thName = createTh();
        thName.dataset.class = "name";
        // thName.style.fontWeight = "";
        thName.classList.add("font-normal"); // thは勝手にboldになる
        const trName = document.querySelector(`tr[data-class='name']`);
        trName.appendChild(thName);
      }
    }
  }
}

export function execSetStyleToSizeTable() {
  const wst = document.getElementById("wrapperSizeTable");
  setMargin();
  setStyle();
  // setRound();

  function setStyle() {
    const width = getScaledValue(GEO_SIZE.width);
    const height = getHeight();
    wst.style.width = `${width}px`;
    wst.style.height = `${height}px`;
    // document.getElementById("sizeTable").style.width = `${width - 2}px`;

    function getHeight() {
      const dispType = getDispTypeByWGTT();
      const result = {
        block: getHeightByBlock(),
        flex: getHeightByFlex(),
        full: "",
      };
      return result[dispType];

      function getHeightByFlex() {
        const pageHeight = getContentHeightOfPage("pageCompare");
        return Math.floor(pageHeight);
      }
      function getHeightByBlock() {
        const pageHeight = getContentHeightOfPage("pageCompare");
        const padding = getStyleValueByNumber({
          id: "pageCompare",
          item: "padding",
        });
        const mainDisplayHeight = getStyleValueByNumber({
          id: "display",
          item: "height",
        });
        const adsHeight = getStyleValueByNumber({
          id: "wrpAds",
          item: "height",
        });
        const adsMargin = getStyleValueByNumber({
          id: "wrpAds",
          item: "marginTop",
        });
        const sizeTableHeight =
          pageHeight - (mainDisplayHeight + padding + adsHeight + adsMargin);
        return Math.floor(sizeTableHeight);
      }
    }
  }
  function setMargin() {
    const wst = document.getElementById("wrapperSizeTable");
    initMargin();
    const padding = 10;
    const scaledPadding = getScaledValue(padding);
    const dispType = getDispTypeByWGTT();
    const func = {
      block: setStyleByBlock,
      flex: setStyleByFlex,
      full: () => {},
    };
    return func[dispType]();

    function initMargin() {
      wst.style.margin = "0px";
    }
    function setStyleByBlock() {
      wst.style.marginTop = `${scaledPadding}px`;
      // wst.style.marginLeft = "auto";
      // wst.style.marginRight = "auto";
    }
    function setStyleByFlex() {
      wst.style.marginLeft = `${scaledPadding}px`;
    }
  }
  // function setRound() {
  //   const round = 14;
  //   const scaledRound = getScaledValue(round);
  //   wst.style.borderRadius = `${scaledRound}px`;
  // }
}

export function initCompareToSizeTable() {
  // get keys of compare targets
  const geometries = getCompareGeometries();
  // init
  geometries.forEach((geoName) => {
    document.querySelectorAll(`td[data-class='${geoName}']`).forEach((td) => {
      td.children[1].textContent = "";
    });
  });
}

export function setCompareToSizeTable() {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImg = document.querySelector(
    `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
  );
  if (!thImg) return;

  const selectedTds = document.querySelectorAll(
    `td[data-col-index='${thImg.dataset.colIndex}']`
  );
  if (selectedTds.length === 0) return;

  const compareThImgs = document.querySelectorAll(
    `th[data-class='img'][data-active='false'][data-compare='${isCompare}']`
  );

  const geometries = getCompareGeometries();
  geometries.forEach((geoName) => {
    compareThImgs.forEach((compareThImg) => {
      // const compareTds = document.querySelectorAll(
      //   `td[data-col-index='${compareThImg.dataset.colIndex}']`
      // );
      const td = document.querySelector(
        `td[data-class='${geoName}'][data-col-index='${compareThImg.dataset.colIndex}']`
      );
      setCompareToCell(td);
      // compareTds.forEach((td) => {
      //   setCompareToCell(td);
      // });
    });

    function setCompareToCell(td) {
      const diffVal = getDifference();
      if (diffVal === 0) return;
      setCompareVal(diffVal);

      function getDifference() {
        const baseTd = document.querySelector(
          `td[data-class='${geoName}'][data-col-index='${thImg.dataset.colIndex}']`
        );
        return (
          Number(td.firstElementChild.textContent) -
          Number(baseTd.firstElementChild.textContent)
        );
      }

      function setCompareVal(diffVal) {
        const prefix = getPrefix(diffVal);
        const roundedDiffVal = mathRoundSetDecimalPlace(diffVal, 1);
        td.children[1].textContent = `(${prefix}${roundedDiffVal})`;

        function getPrefix(result) {
          if (result < 0) {
            return "";
          }
          return "+";
        }
      }
    }
  });
}

export const COL_COUNT = {
  st: document.getElementById("sizeTable"),
  get: () => {
    return Number(COL_COUNT.st.dataset.colCount);
  },
  set: (num) => {
    // COL_COUNT.st.dataset.colCount = Number(COL_COUNT.st.dataset.colCount) + 1;
    COL_COUNT.st.dataset.colCount = Number(COL_COUNT.st.dataset.colCount) + num;
  },
};

// export const getColCount = () => {
//   const st = document.getElementById("sizeTable");
//   const count = Number(st.dataset.colCount);
//   st.dataset.colCount = count + 1;
//   return count;
// };

// export const getColCount = () => {
//   return document.getElementById("sizeTable").dataset.colCount;
// };

export const getThImg = (num) => {
  return document.querySelector(
    `th[data-class='img'][data-col-index='${num}']`
  );
};

export const setWordingToThNameHeader = (mkr, mdl, year) => {
  const mkr2 = getWordingNameByAll(mkr);
  const mdl2 = getWordingNameByAll(mdl);
  const year2 = getYears(year);
  const th = document.querySelector(
    `th[data-class='name'][data-col-index='header']`
  );
  th.dataset.mkr = mkr;
  th.dataset.mdl = mdl;
  th.dataset.year = year;
  th.dataset.wordingEng = `${mkr2.eng}  ${mdl2.eng}  ${year2}`;
  th.dataset.wordingJpn = `${mkr2.jpn}  ${mdl2.jpn}  ${year2}`;
};

export const setBikeNameToThNameHeader = () => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const lang = document.getElementById("display").dataset.lang;
  const map = { eng: "wordingEng", jpn: "wordingJpn" };
  const th = document.querySelector(
    `th[data-class='name'][data-col-index='header']`
  );
  th.textContent = getText();

  function getText() {
    const name = th.dataset[map[lang]];
    if (!name) return null;
    if (isCompare === "false") {
      // const rep = " ";
      // const names = name.split(rep);
      // const size = `${rep}${names[names.length - 1]}`;
      // const text = name.replace(size, "");
      return name;
    } else {
      return "";
    }
  }
};

export const setWordingToThName = () => {
  const thImgs = document.querySelectorAll(`th[data-class='img']`);
  if (!thImgs === 0) return;

  for (let i = 0; i < thImgs.length; i++) {
    const thName = document.querySelector(
      `th[data-class='name'][data-col-index='${thImgs[i].dataset.colIndex}']`
    );
    if (!thName) continue;
    const t = thImgs[i];
    const mkr = getWordingNameByAll(t.dataset.mkr);
    const mdl = getWordingNameByAll(t.dataset.mdl);
    const year = getYears(t.dataset.years);
    if (!mkr || !mdl || !year) continue;
    thName.dataset.wordingEng = `${mkr.eng}  ${mdl.eng}  ${year}  ${t.dataset.size}`;
    thName.dataset.wordingJpn = `${mkr.jpn}  ${mdl.jpn}  ${year}  ${t.dataset.size}`;
  }
};

export const setBikeNameToThName = () => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const lang = document.getElementById("display").dataset.lang;
  const map = { eng: "wordingEng", jpn: "wordingJpn" };
  const ts = document.querySelectorAll(
    `th[data-class='name']:not([data-col-index='header'])`
  );
  if (!ts === 0) return;
  ts.forEach((t) => {
    t.textContent = getText(t);
  });

  function getText(t) {
    const name = t.dataset[map[lang]];
    if (!name) return null;
    if (isCompare === "false") {
      const rep = " ";
      const names = name.split(rep);
      const size = `${names[names.length - 1]}`;
      return size;
    } else {
      return name;
    }
  }
};

export const setSizeTable = () => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  if (isCompare === "false") {
    const thName = document.querySelector(
      `th[data-class='name'][data-col-index='header']`
    );
    setSizeTableBy(
      "size",
      thName.dataset.mkr,
      thName.dataset.mdl,
      thName.dataset.year
    );
  } else {
    setSizeTableBy("compare");
  }
};
