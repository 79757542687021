"use strict";

import { resize } from "./modules/func/resize";
import {
  execSetEventToAll,
  onRotationDisplay,
} from "./modules/func/execSetEventToAll";
import { execSetStyleToAll } from "./modules/func/execSetStyleToAll";
import { execSetZindexPre } from "./modules/func/zindex";
import {
  setBikeDataToSideMenuMakerModel,
  setBikeDataToSideMenuYear,
  setStyleToSideMenu,
  setWordingToYearH,
} from "./modules/func/sideMenu";
import { createSizeTable } from "./modules/func/sizeTable";
import { execSetClassToAll } from "./modules/func/execSetClassToAll";
import { execGetBikeData } from "./modules/func/php";
import {
  execCreateDispGrid,
  createGridBase,
  setStyleToBikeName,
  setStyleToWrpImgDisp,
  createBtnMagnifyToDisplay,
  duplicateImgDisp,
} from "./modules/func/display";
import { execCreateWrapperBikes } from "./modules/func/bike";
import { changeScale } from "./modules/func/scale";
import {
  changeWindowByFlexOrBlock,
  getDispTypeByWGTT,
} from "./modules/func/_common";
import {
  setBikeNameToSimilarOfSideMenu,
  setSimilar,
  setWordingToSimilarOfSideMenu,
} from "./modules/func/similar";
import {
  duplicateChangeLanguage,
  setWordingAfterToDataset,
  setWordingToDataset,
} from "./modules/func/wording";

(function begin() {
  console.clear();
  console.log(`begin`);

  execSetZindexPre();
  const shield = document.getElementById("shield");
  const overlay = document.getElementById("overlay");
  const iconLoader = document.getElementById("iconLoader");
  shield.style.display = "";
  overlay.style.display = "";
  iconLoader.style.display = "";
  // テキスト表示のちらつき防止の為、データ取得から設定完了まで画面を非表示にしている
  document.getElementsByTagName("body")[0].style.display = "";

  const pageStart = document.getElementById("pageStart");
  pageStart.style.display = "none";

  execCreateDispGrid();
  createGridBase();
  createSizeTable();
  changeScale("set");
  execSetStyleToAll();
  onRotationDisplay();

  // resizeでiconLoaderの位置を設定
  resize();
  proceedAsyncFunctions();

  async function proceedAsyncFunctions() {
    const bikes = await execGetBikeData("modelAll");
    setBikeDataToSideMenuMakerModel(bikes);
    await setBikeDataToSideMenuYear(bikes);
    const wording = await execGetBikeData("wording");
    setWordingToDataset(wording);
    const wording2 = await execGetBikeData("wordingAfter");
    setWordingAfterToDataset(wording2);
    const sizes = await execGetBikeData("sizeAll");
    setSimilar(sizes);
    setWordingToYearH();
    setStyleToSideMenu();
    setStyleToBikeName();
    setStyleToWrpImgDisp();
    execSetClassToAll();
    execSetEventToAll();

    const dispType = getDispTypeByWGTT();
    if (dispType === "full") {
      changeWindowByFlexOrBlock("full");
      resize();
    }

    document
      .querySelector(`li[data-class='naviList'][data-page='pageCompare']`)
      .click();

    const btnCompare = document.getElementById("btnCompare");
    btnCompare.click(); // 1回目でstyle設定
    btnCompare.click(); // 2回目でサイズ選択へ戻す

    document
      .querySelector(`li[data-class='naviList'][data-page='pageStart']`)
      .click();
    document.getElementById("btnWordingToEng").click();

    shield.style.display = "none";
    overlay.style.display = "";
    iconLoader.style.display = "none";
    pageStart.style.display = "";
    // テキスト表示のちらつき防止の為、データ取得から設定完了まで画面を非表示にしている
  }
})();
