"use strict";

export const GEOMETRY_SPEC = {
  count: {
    wordingId: "stCount",
    fullName: "Count",
    shortName: "Count",
    type: "string",
    convert: false,
    draw: "none",
    display: "block",
    compare: true,
  },
  // registDate: {
  //   fullName: "Regist Date",
  //   shortName: "Regist Date",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: false,
  // },
  // country: {
  //   fullName: "Country",
  //   shortName: "Country",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: false,
  // },
  // maker: {
  //   fullName: "maker",
  //   shortName: "maker",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: false,
  // },
  // md_mkr_mdl: {
  //   fullName: "md_mkr_mdl",
  //   shortName: "md_mkr_mdl",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: false,
  // },
  // sizeNo: {
  //   fullName: "sizeNo",
  //   shortName: "sizeNo",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: false,
  // },
  // model: {
  //   fullName: "Model",
  //   shortName: "Model",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: false,
  // },
  // startYear: {
  //   fullName: "Start year",
  //   shortName: "Start year ",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: true,
  // },
  // endYear: {
  //   fullName: "End year",
  //   shortName: "End year",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: true,
  // },
  size: {
    wordingId: "stSize",
    fullName: "Size",
    shortName: "Size",
    type: "string",
    convert: false,
    draw: "none",
    display: "none",
    compare: false,
  },
  // gc_stDia: {
  //   fullName: "SeatTube Diameter",
  //   shortName: "ST Dia",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  seatTubeCtoT: {
    wordingId: "stSTCtoT",
    fullName: "SeatTube Center To Top",
    shortName: "ST CtoT",
    type: "number",
    convert: true,
    draw: "rect",
    display: "block",
    compare: true,
  },
  seatTubeCtoC: {
    wordingId: "stSTCtoC",
    fullName: "SeatTube Center To Center",
    shortName: "ST CtoC",
    type: "number",
    convert: true,
    draw: "rect",
    display: "block",
    compare: true,
  },
  seatTubeAngle: {
    wordingId: "stSTA",
    fullName: "SeatTube Angle",
    shortName: "ST Ang",
    type: "number",
    convert: false,
    draw: "arc",
    display: "block",
    compare: true,
  },
  // stCtoCVrt: {
  //   fullName: "SeatTube Center To Center Vertically",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // stCtoCHrz: {
  //   fullName: "SeatTube Center To Center Horizontally",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // stCtoTVrt: {
  //   fullName: "SeatTube Center To Top Vertically",
  //   shortName: "ST CtoT Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // stCtoTHrz: {
  //   fullName: "SeatTube Center To Top Horizontally",
  //   shortName: "ST CtoT Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // gc_stTpr: {
  //   fullName: "SeatTube Tapered",
  //   shortName: "ST Tap",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // gc_ttDia: {
  //   fullName: "TopTube Diameter",
  //   shortName: "TT Dia",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  effectiveTopTube: {
    wordingId: "stETT",
    fullName: "Effective TopTube",
    shortName: "ETT",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  // effectiveTopTubeGridST: {
  //   wordingId: "",
  //   fullName: "EffectiveTopTubeGridToSeatTube",
  //   shortName: "ETTGridST",
  //   type: "number",
  //   convert: true,
  //   draw: "line",
  //   display: "block",
  //   compare: true,
  // },
  // effectiveTopTubeGridHT: {
  //   wordingId: "",
  //   fullName: "EffectiveTopTubeGridToHeadTube",
  //   shortName: "ETTGridHT",
  //   type: "number",
  //   convert: true,
  //   draw: "line",
  //   display: "block",
  //   compare: true,
  // },
  topTube: {
    wordingId: "stTT",
    fullName: "TopTube",
    shortName: "TT",
    type: "number",
    convert: true,
    draw: "rect",
    display: "block",
    compare: true,
  },
  // ttAgl: {
  //   fullName: "TopTube Angle",
  //   shortName: "TT Ang",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // gc_ttTpr: {
  //   fullName: "TopTube Tapered",
  //   shortName: "TT Tap",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: false,
  // },
  // gc_htDia: {
  //   fullName: "HeadTube Diameter",
  //   shortName: "HT Dia",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  headTube: {
    wordingId: "stHT",
    fullName: "HeadTube",
    shortName: "HT",
    type: "number",
    convert: true,
    draw: "rect",
    display: "block",
    compare: true,
  },
  // htVrt: {
  //   fullName: "HeadTube Vertically",
  //   shortName: "HT Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // htHrz: {
  //   fullName: "HeadTube Horizontally",
  //   shortName: "HT Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  headTubeAngle: {
    wordingId: "stHTA",
    fullName: "HeadTube Angle",
    shortName: "HT Ang",
    type: "number",
    convert: false,
    draw: "arc",
    display: "block",
    compare: true,
  },
  // HeadTubeTopTubeCtoCVrt: {
  //   fullName: "HeadTube And TopTube Center To Center Vertically",
  //   shortName: "HT TT CtoC Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // HeadTubeDownTubeCtoCVrt: {
  //   fullName: "HeadTube And DownTube Center To Center Vertically",
  //   shortName: "HT DT CtoC Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // gc_htTpr: {
  //   fullName: "HeadTube Tapered",
  //   shortName: "HT Tap",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // headSetBottom: {
  //   wordingId: "",
  //   fullName: "BottomCup",
  //   shortName: "BC",
  //   type: "number",
  //   convert: true,
  //   draw: "rect",
  //   display: "block",
  //   compare: true,
  // },
  // hsbVrt: {
  //   fullName: "BottomCup Vertically",
  //   shortName: "BC Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // hsbHrz: {
  //   fullName: "BottomCup Horizontally",
  //   shortName: "BC Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // gc_csDia: {
  //   fullName: "ChainStay Diameter",
  //   shortName: "CS Dia",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  chainStay: {
    wordingId: "stCS",
    fullName: "ChainStay",
    shortName: "CS",
    type: "number",
    convert: true,
    draw: "rect",
    display: "block",
    compare: true,
  },
  // csHrz: {
  //   fullName: "ChainStay Horizontally",
  //   shortName: "CS Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // csAgl: {
  //   fullName: "ChainStay Angle",
  //   shortName: "CS Ang",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // gc_csTpr: {
  //   fullName: "ChainStay Tapered",
  //   shortName: "CS Tap",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // gc_dtDia: {
  //   fullName: "DownTube Diameter",
  //   shortName: "DT Dia",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // downTube: {
  //   wordingId: "",
  //   fullName: "DownTube",
  //   shortName: "DT",
  //   type: "number",
  //   convert: true,
  //   draw: "rect",
  //   display: "block",
  //   compare: true,
  // },
  // dtAgl: {
  //   fullName: "DownTube Angle",
  //   shortName: "DT Ang",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // gc_dtTpr: {
  //   fullName: "DownTube Tapered",
  //   shortName: "DT Tap",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // gc_ssDia: {
  //   fullName: "SeatStay Diameter",
  //   shortName: "SS Dia",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // seatStay: {
  //   wordingId: "",
  //   fullName: "SeatStay",
  //   shortName: "SS",
  //   type: "number",
  //   convert: true,
  //   draw: "rect",
  //   display: "block",
  //   compare: true,
  // },
  // ssAgl: {
  //   fullName: "SeatStay Angle",
  //   shortName: "SS Ang",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // seatStayOffsetVrt: {
  //   fullName: "SeatStay Offset Vertically",
  //   shortName: "SS Ofs Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // seatStayOffsetHrz: {
  //   fullName: "SeatStay Offset Horizontally",
  //   shortName: "SS Ofs Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // BBSeatTubeOffsetHrz: {
  //   fullName: "BottomBracket And SeatStay Offset Horizontally",
  //   shortName: "BB ST Ofs Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // bbDroStHrz: {
  //   fullName: "BottomBracketDrop And SeatStay Horizontally",
  //   shortName: "BBD ST Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // gc_ssTpr: {
  //   fullName: "SeatStay Tapered",
  //   shortName: "SS Tap",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: false,
  // },
  frontCenter: {
    wordingId: "stFC",
    fullName: "FrontCenter",
    shortName: "FC",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  // fcEfc: {
  //   fullName: "Effective FrontCenter",
  //   shortName: "EFC",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // fcAgl: {
  //   fullName: "FrontCenter Angle",
  //   shortName: "FC Ang",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  wheelBase: {
    wordingId: "stWB",
    fullName: "WheelBase",
    shortName: "WB",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  bbDrop: {
    wordingId: "stBBD",
    fullName: "BottomBracket Drop",
    shortName: "BB Drop",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  bbHeight: {
    wordingId: "stBBH",
    fullName: "BottomBracket Height",
    shortName: "BB Hei",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  // bottomBracket: {
  //   wordingId: "",
  //   fullName: "BottomBracket",
  //   shortName: "BB",
  //   type: "number",
  //   convert: true,
  //   draw: "arc",
  //   display: "block",
  //   compare: true,
  // },
  // gc_fDia: {
  //   fullName: "Fork Diameter",
  //   shortName: "F Dia",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  forkStraight: {
    wordingId: "stF1",
    fullName: "Fork",
    shortName: "F",
    type: "number",
    convert: true,
    draw: "rect",
    display: "block",
    compare: true,
  },
  // forkType: {
  //   wordingId: "",
  //   fullName: "Fork Type",
  //   shortName: "Fork Type",
  //   type: "string",
  //   convert: false,
  //   draw: "info",
  //   display: "block",
  //   compare: false,
  // },
  forkCurve: {
    wordingId: "stF2",
    fullName: "Fork",
    shortName: "F",
    type: "number",
    convert: true,
    draw: "rect",
    display: "none",
    compare: true,
  },
  // fLenNR: {
  //   fullName: "Fork Non Rake",
  //   shortName: "F Non R",
  //   type: "number",
  //   convert: true,
  //   draw: "line",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // forkLengthNonRakeHrz: {
  //   fullName: "Fork Non Rake Horizontally",
  //   shortName: "F Non R Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // forkLengthNonRakeVrt: {
  //   fullName: "Fork Non Rake Vertically",
  //   shortName: "F Non R Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  forkRake: {
    wordingId: "stR",
    fullName: "Fork Rake",
    shortName: "R",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  // fAgl: {
  //   fullName: "Fork Angle",
  //   shortName: "F Ang",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // fHrz: {
  //   fullName: "Fork Horizontally",
  //   shortName: "F Hrz",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // fVrt: {
  //   fullName: "Fork Vertically",
  //   shortName: "F Vrt",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: true,
  //   compare: true,
  // },
  // gc_fTpr: {
  //   fullName: "Fork Tapered",
  //   shortName: "F Tap",
  //   type: "number",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: false,
  // },
  standOver: {
    wordingId: "stSO",
    fullName: "StandOver",
    shortName: "SO",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  stack: {
    wordingId: "stStack",
    fullName: "Stack",
    shortName: "Stack",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  reach: {
    wordingId: "stReach",
    fullName: "Reach",
    shortName: "Reach",
    type: "number",
    convert: true,
    draw: "line",
    display: "block",
    compare: true,
  },
  rimSizeFront: {
    wordingId: "stRimF",
    fullName: "rimSizeFront",
    shortName: "rimF",
    type: "number",
    convert: true,
    draw: "arc",
    display: "none",
    compare: true,
  },
  rimSizeRear: {
    wordingId: "stRimR",
    fullName: "rimSizeRear",
    shortName: "rimR",
    type: "number",
    convert: true,
    draw: "arc",
    display: "none",
    compare: true,
  },
  tireSizeFront: {
    wordingId: "stTireF",
    fullName: "tireSizeFront",
    shortName: "tireF",
    type: "number",
    convert: true,
    draw: "arc",
    display: "none",
    compare: true,
  },
  tireSizeRear: {
    wordingId: "stTireR",
    fullName: "tireSizeRear",
    shortName: "tireR",
    type: "number",
    convert: true,
    draw: "arc",
    display: "none",
    compare: true,
  },
  tire: {
    wordingId: "stTC",
    fullName: "Tire Clearance",
    shortName: "tire",
    type: "string",
    convert: false,
    draw: "none",
    display: "block",
    compare: false,
  },
  // rearWheelEndToCenter: {
  //   wordingId: "",
  //   fullName: "RearWheel End To Center",
  //   shortName: "RW EtoC",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   compare: true,
  // },
  // rearWheelOffset: {
  //   fullName: "RearWheel Offset",
  //   shortName: "RW Ofs",
  //   type: "number",
  //   convert: true,
  //   draw: "none",
  //   display: "block",
  //   clickEvent: false,
  //   compare: true,
  // },
  // rearEnd: {
  //   wordingId: "",
  //   fullName: "RearEnd",
  //   shortName: "RE",
  //   type: "number",
  //   convert: true,
  //   draw: "rect",
  //   display: "block",
  //   compare: true,
  // },
  seatPost: {
    wordingId: "stSP",
    fullName: "SeatPost",
    shortName: "SP",
    type: "number",
    convert: false,
    draw: "none",
    display: "none",
    compare: true,
  },
  headSet: {
    wordingId: "stHS",
    fullName: "HeadSet",
    shortName: "HS",
    type: "string",
    convert: false,
    draw: "none",
    display: "none",
    compare: false,
  },
  calculated: {
    wordingId: "stCalc",
    fullName: "Calculation",
    shortName: "Calc",
    type: "string",
    convert: false,
    draw: "none",
    display: "none",
    compare: false,
  },
  referenced: {
    wordingId: "stRef",
    fullName: "referenced",
    shortName: "ref",
    type: "string",
    convert: false,
    draw: "none",
    display: "none",
    compare: false,
  },
  // notice: {
  //   fullName: "Notice",
  //   shortName: "Ntc",
  //   type: "string",
  //   convert: false,
  //   draw: "none",
  //   display: "block",
  //   compare: false,
  // },
  source: {
    wordingId: "stSrc",
    fullName: "Source",
    shortName: "Src",
    type: "string",
    convert: false,
    draw: "none",
    display: "block",
    compare: false,
  },
  similar: {
    wordingId: "stSimi",
    fullName: "Similar",
    shortName: "Simi",
    type: "string",
    convert: false,
    draw: "none",
    display: "block",
    compare: false,
  },
};

export const getCompareGeometries = () => {
  return Object.keys(GEOMETRY_SPEC).filter((geo) => GEOMETRY_SPEC[geo].compare);
};
