/* eslint-disable spaced-comment */
"use strict";
import {
  getScaleByDispType,
  getStyleValueByNumber,
  getDispTypeByWGTT,
  changeWindowByFlexOrBlock,
} from "./_common";
import { setStyleToSideMenu } from "./sideMenu";
import { execSetStyleToSizeTable, setStyleToTDTH } from "./sizeTable";
import { execSetStyleToNavi } from "./navi";
import { GEO_SIZE } from "./_const";
import { mathRoundSetDecimalPlace } from "./_math";
import { execSetStyleToMainDisplay, offsetToDispGrid } from "./display";
import { setStyleToWrpDispTabTable } from "./wrpDispTabTable";
import {
  execSetStyleToPages,
  setStyleToBtnPageClose,
  setStyleToPageContext,
} from "./page";
import {
  setStyleToFullImg,
  setStyleToContentBox,
  setStyleToTmbImgs,
  setStyleToWrapperImgsByYear,
} from "./img";
import { LOWER_MENU, setStyleToLowerMenu } from "./lowerMenu";
import { setStyleToAds } from "./ads";

export async function resize() {
  console.log("resize");
  const dispType = getDispTypeByWGTT();
  const main = document.getElementById("main");
  const clientWidth = document.documentElement.clientWidth;
  const clientHeight = document.documentElement.clientHeight;

  if (dispType === "full") {
    main.style.left = `${LOWER_MENU.HEIGHT_BASE}px`;
    // main.style.bottom = "";
    main.style.height = `${clientHeight}px`;
    main.style.width = `${clientWidth - LOWER_MENU.HEIGHT_BASE}px`;
  } else {
    main.style.left = "";
    // main.style.bottom = "0px";
    main.style.height = `${clientHeight - LOWER_MENU.HEIGHT_BASE}px`;
    main.style.width = `${clientWidth}px`;
  }

  if (isChangeDispType()) {
    // 縦横の比率でflex/block切替
    rotateDispTable();
    // return;
  }

  // setStyleToMain();
  setStyleToPages();
  setStyleToPageContext();
  setScaleByResize(); // scaleはcomparePageのwidth,heightを参考にしている
  setStyleToOverlay();
  execSetStyleToPages();
  execSetStyleToNavi();

  setStyleToLowerMenu();

  setStyleToGeometryDisplay();
  execSetStyleToMainDisplay();
  setStyleToAds();
  offsetToDispGrid();
  execSetStyleToSizeTable();
  setStyleToTDTH();
  setStyleToIconLoaderByCenter();
  setStyleToWrpDispTabTable();
  setStyleToSideMenu();
  setStyleToTmbImgs();
  setStyleToFullImg();
  setStyleToContentBox();
  setStyleToWrapperImgsByYear();

  function setStyleToOverlay() {
    const overlay = document.getElementById("overlay");
    const height = document.documentElement.clientHeight;
    overlay.style.height = `${height}px`;
  }
  // function setStyleToNavi() {
  //   if (dispType === "flex") {
  //     // exSetStyleToNaviLists("hide");
  //     // execSetStyleToNavi("hide");
  //   } else if (dispType !== "full") {
  //     // exSetStyleToNaviLists("show");
  //     // execSetStyleToNavi("show");
  //   }
  // }
  // function setStyleToMain() {
  //   const main = document.getElementById("main");
  //   const height = (() => {
  //     const clientHeight = document.documentElement.clientHeight;
  //     return clientHeight - LOWER_MENU.HEIGHT_BASE;
  //   })();
  //   main.style.height = `${height}px`;
  //   main.style.width = `${document.documentElement.clientWidth}px`;
  // }
  function setStyleToPages() {
    const height = getStyleValueByNumber({ id: "main", item: "height" });
    const pages = document.querySelectorAll(`article[data-class='page']`);
    pages.forEach((page) => (page.style.height = `${height}px`));
  }
  function setStyleToGeometryDisplay() {
    const scale = getScaleByDispType();
    const geoDisp = document.getElementById("displayScaled");
    geoDisp.style.transform = `scale(${scale}, ${scale})`;
  }
  function setStyleToIconLoaderByCenter() {
    const iconLoader = document.getElementById("iconLoader");
    const scale = getScaleByDispType();
    iconLoader.style.top = `${
      document.documentElement.clientHeight / 2 - iconLoader.height / 2
    }px`;
    iconLoader.style.left = `${
      document.documentElement.clientWidth / 2 - iconLoader.width / 2
    }px`;
    iconLoader.style.transform = `scale(${scale}, ${scale})`;
  }
  function isChangeDispType() {
    const dispType2 = getNewDispType();
    if (dispType === "full") {
      return false;
    }
    if (dispType === dispType2) {
      return false;
    }
    return true;

    function getNewDispType() {
      if (window.innerHeight > window.innerWidth) {
        return "block";
      } else {
        return "flex";
      }
    }
  }
  function rotateDispTable() {
    const setDispType = getSetDispType();
    changeWindowByFlexOrBlock(setDispType);
    // exSetNavi(setDispType);
    // resize();

    function getSetDispType() {
      const dispType = getDispTypeByWGTT();
      if (dispType === "flex") {
        return "block";
      }
      return "flex";
    }
  }
}

export function getContentHeightOfPage(pageId) {
  const padding = getStyleValueByNumber({
    id: pageId,
    item: "padding",
  });
  const mainHeight = getStyleValueByNumber({
    id: pageId,
    item: "height",
  });
  const height = mainHeight - padding * 2;
  return height;
}
export function getContentWidthOfPage(pageId) {
  const padding = getStyleValueByNumber({
    id: pageId,
    item: "padding",
  });
  const clientWidth = document.documentElement.clientWidth;
  const width = clientWidth - padding * 2;
  return width;
}

export function setScaleByResize() {
  const dispType = getDispTypeByWGTT();
  const scaleByWidth = getScaleBy("width");
  const scaleByHeight = getScaleBy("height");
  const value = getValue();
  if (!value) {
    return;
  }
  const wdtt = document.getElementById("wrpDispTabTable");
  wdtt.dataset.scaleByWidth = value.width;
  wdtt.dataset.scaleByHeight = value.height;

  function getScaleBy(getType) {
    const adsMap = {
      block: { width: 0, height: 100 },
      flex: { width: 0, height: 100 },
      full: { width: 100, height: 0 },
    };
    // const val1 = GEO_SIZE[getType];
    const val2 = GEO_SIZE[getType] + adsMap[dispType][getType];
    const scale = getScaleByDispType();
    const base = Math.floor(val2 * scale);
    const target = getTarget(getType);
    const scaleTemp = target / base;
    return mathRoundSetDecimalPlace(scaleTemp, GEO_SIZE.resizeDecimal);

    function getScaleByDispType() {
      const result = {
        width: {
          block: GEO_SIZE.scaleOfWidthByBlock,
          flex: GEO_SIZE.scaleOfWidthByFlex,
          full: GEO_SIZE.scaleOfWidthByFull,
        },
        height: {
          block: GEO_SIZE.scaleOfHeightByBlock,
          flex: GEO_SIZE.scaleOfHeightByFlex,
          full: GEO_SIZE.scaleOfHeightByFull,
        },
      };
      return result[getType][dispType];
    }
  }
  function getTarget(getType) {
    const result = {
      width: getContentWidthOfPage,
      height: getContentHeightOfPage,
    };
    return result[getType]("pageCompare");
  }
  function getValue() {
    const isBiggerWidth = isDisplayBiggerThanWindowBy("width");
    const isBiggerHeight = isDisplayBiggerThanWindowBy("height");
    const key = `${isBiggerWidth}_${isBiggerHeight}`;
    const result2 = {
      false_false: { width: scaleByWidth, height: scaleByHeight },
      true_false: { width: scaleByWidth, height: scaleByWidth },
      false_true: { width: getWidth(), height: scaleByHeight },
      true_true: null,
    };
    return result2[key];

    function getWidth() {
      const scale = scaleByHeight * GEO_SIZE.adjustResizeHeightValue;
      return mathRoundSetDecimalPlace(scale, GEO_SIZE.resizeDecimal);
    }
  }
  function isDisplayBiggerThanWindowBy(valueType) {
    const value = getValue();
    const scale = getScale();
    const base = parseInt(value * scale);
    const target = getTarget(valueType);
    return base > target;

    function getScale() {
      // 対になるscaleで比較する
      const result = {
        width: scaleByHeight,
        height: scaleByWidth,
      };
      return result[valueType];
    }
    function getValue() {
      const result = {
        height: {
          block: GEO_SIZE.height,
          flex: GEO_SIZE.height,
          full: GEO_SIZE.height,
        },
        width: {
          block: GEO_SIZE.width,
          flex: GEO_SIZE.width,
          full: GEO_SIZE.width,
        },
      };
      return result[valueType][dispType];
    }
  }
}
