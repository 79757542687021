"use strict";

import { getYears } from "./_common";
import { getSimilarBikesToContentBox } from "./similar";
import { getWordingAfterBy, getWordingName } from "./wording";

export function showTextBy(thImg, setType) {
  // init
  document.getElementById("imgsByYear").style.display = "none";
  document.getElementById("contentBox").style.display = "";

  // set
  document.getElementById("hTagByContentBox").textContent = getTitle();
  document.getElementById("contentP").innerText = getContent();
  document.getElementById("wrapperImgsByYear").style.display = "";

  function getTitle() {
    if (setType === "similar") {
      const mkr = thImg.dataset.mkr;
      const mdl = thImg.dataset.mdl;
      const years = getYears(thImg.dataset.years);
      const size = thImg.dataset.size;
      return `${getWordingName(mkr)}  ${getWordingName(
        mdl
      )}  ${years}  ${size}`;
    } else if (setType === "maxCompare") {
      return getWordingAfterBy(
        document.getElementById("contentBox"),
        "cbNoticeTitle"
      );
    }
  }
  function getContent() {
    if (setType === "similar") {
      return getSimilarBikesToContentBox(thImg);
    } else if (setType === "maxCompare") {
      return getWordingAfterBy(
        document.getElementById("contentBox"),
        "cbNoticeContent1"
      );
    }
  }
}

export const setAlertToContentBox = (thImg) => {
  // init
  const ps = document.querySelectorAll(`p[data-class='pSimilarCB']`);
  ps.forEach((p) => p.remove());

  // set
  const div = document.getElementById("wrpSimilar");
  const btn = document.querySelector(
    `td[data-class='similar'][data-col-index='${thImg.dataset.colIndex}'] button`
  );
  const bikes = btn.dataset.similar3.split(",");
  bikes.forEach((bike) => {
    const names = bike.split("_");
    const mkr = getWordingName(names[0]);
    const mdl = getWordingName(names[1]);
    const years = getYears(names[2]);
    const size = names[3];
    const p = document.createElement("p");
    p.dataset.class = "pSimilarCB";
    p.classList.add("ml-2");
    p.textContent = `${mkr}  ${mdl}  ${years}  ${size}`;
    div.appendChild(p);
  });
  document.getElementById("contentP").appendChild(div);
};
