"use strict";
import { getEventTargetsByBetween } from "./_common";
import { isVisiblePartsValue } from "./_constDrawPartsValue";
import { ZINDEX_WITH_DISP } from "./_const";
import { execChangeBase, execChangeIndicator } from "./display";
import { initColorToValue, setColorToValue } from "./values";
import { changeScale } from "./scale";
import { setBackGroundColorToRowHeader } from "./sizeTable";

export function onClickHighlight(event) {
  console.log("onClickHighlight");
  const getTarget = () => {
    const ts0 = getEventTargetsByBetween(event);
    if (!ts0) return null;
    // sizeTableのth
    const t0 = ts0.find(
      (t) => t.tagName.toLowerCase() === "th" && t.scope === "row"
    );
    if (t0) return t0;
    // displayのvalue
    const t1 = ts0.find(
      (t) =>
        t.tagName.toLowerCase() === "button" &&
        (t.parentElement.id === "wrapperValueLeft" ||
          t.parentElement.id === "wrapperValueRight")
    );
    if (t1) return t1;
    return null;
  };
  const target = getTarget();
  if (!target) return;

  execChangeIndicator("set");
  execChangeBase("set");
  changeScale("set");
  execHighlight(target);
}

export function execHighlight(target) {
  // execHighlightの基準はptag作成の基準となるDRAW_PARTS_VALUEのvisible
  // DRAW_PARTS_VALUE visible:true > 実施
  console.log("execHighlight");
  const color = "bg-yellow-200";
  const classNameOld =
    document.getElementById("display").dataset.highlightParts;
  const classNameNew = getClassNameNew();
  if (!classNameNew) return;
  const eventType = getEventType();
  if (!eventType) return null;
  initHighLight();
  if (eventType !== "reset") setInfoToDisp();
  if (eventType !== "cancel") {
    setHighLight();
    if (classNameNew !== "") setItemForShowHide();
  }

  // 呼び出し元別で情報が入ったキーとなる要素を取得
  function getClassNameNew() {
    // クリックイベントではなく既にhighlight済みの場合 > バイク描画が変更されているので再設定
    if (!target) {
      if (!classNameOld) return null;
      return classNameOld;
    }
    // クリックイベントの場合
    // event/targetはクラス名の取得に必要なだけ＞クラス名が分かれば不要
    return target.dataset.class;
  }
  function getEventType() {
    if (isResetHighlight()) return "reset";
    if (isCancelHighlight()) return "cancel";
    if (isSetHighlight()) return "set";
    return null;

    function isResetHighlight() {
      return !target && classNameNew === classNameOld;
    }
    function isCancelHighlight() {
      return target && classNameNew === classNameOld;
    }
    function isSetHighlight() {
      return isVisiblePartsValue(classNameNew);
    }
  }
  function initHighLight() {
    initColor();
    initZindex();
    setBackGroundColorToRowHeader();

    function initColor() {
      if (!classNameOld) return;
      document
        .querySelectorAll(`canvas[data-class='${classNameOld}Highlight']`)
        .forEach((t) => {
          t.style.backgroundColor = "";
          t.style.outlineColor = "transparent";
        });
      const ts = document.querySelectorAll(
        `tr[data-class='${classNameOld}'],th[data-class='${classNameOld}']`
      );
      ts.forEach((t) => t.classList.remove(color));
      initColorToValue();
    }
    function initZindex() {
      const q0 = `canvas[data-class='${classNameOld}']`;
      const q1 = `canvas[data-class='${classNameOld}Highlight']`;
      document
        .querySelectorAll(`${q0},${q1}`)
        .forEach((t) => (t.style.zIndex = ZINDEX_WITH_DISP[t.dataset.zIndex]));
    }
  }
  function setHighLight() {
    setColor();
    setZindex();

    function setColor() {
      document
        .querySelectorAll(`canvas[data-class='${classNameNew}Highlight']`)
        .forEach((t) => {
          t.style.backgroundColor = "rgba(255,255,0,0.5)";
          t.style.outlineColor = "rgb(255,255,0)";
        });
      const ts = document.querySelectorAll(
        `tr[data-class='${classNameNew}'],th[data-class='${classNameNew}']`
      );
      ts.forEach((t) => {
        t.classList.remove("bg-neutral-100");
        t.classList.remove("bg-white");
        t.classList.add(color);
      });

      setColorToValue();
    }
    function setZindex() {
      const q0 = `canvas[data-class='${classNameNew}']`;
      const q1 = `canvas[data-class='${classNameNew}Highlight']`;
      document
        .querySelectorAll(`${q0},${q1}`)
        .forEach(
          (t) =>
            (t.style.zIndex =
              ZINDEX_WITH_DISP[t.dataset.zIndex] + ZINDEX_WITH_DISP.LINE)
        );
    }
  }
  function setInfoToDisp() {
    const map = { set: classNameNew, cancel: "" };
    document.getElementById("display").dataset.highlightParts = map[eventType];
  }
  function setItemForShowHide() {
    const q1 = `canvas[data-class='${classNameNew}']:not([data-z-index='FRAME'])`;
    const q2 = `canvas[data-class='${classNameNew}Highlight']`;
    document
      .querySelectorAll(`${q1},${q2}`)
      .forEach((t) => (t.style.display = ""));
  }
}
