"use strict";

import { getDispTypeByWGTT, getScaledValue } from "./_common";
import { GEO_SIZE } from "./_const";

export const setStyleToAds = () => {
  const dispType = getDispTypeByWGTT();
  const wrpAds = document.getElementById("wrpAds");
  const padding = 10;
  const scaledPadding = getScaledValue(padding);

  if (dispType === "block" || dispType === "flex") {
    wrpAds.style.width = `${getScaledValue(GEO_SIZE.width)}px`;
    wrpAds.style.height = `100px`;
    wrpAds.style.marginTop = `${scaledPadding}px`;
    wrpAds.style.marginLeft = "";
  } else if (dispType === "full") {
    wrpAds.style.width = "100px";
    wrpAds.style.height = `${getScaledValue(GEO_SIZE.height)}px`;
    wrpAds.style.marginTop = "";
    wrpAds.style.marginLeft = `${scaledPadding}px`;
  }
};
