"use strict";
import { ZINDEX_WITH_DISP, ZINDEX } from "./_const";

export function execSetZindexPre() {
  // th,tr_sizeはexecCreateSizeTableで設定
  document.getElementById("sideMenu").style.zIndex = ZINDEX.SIDE_MENU;
  document.getElementById("overlay").style.zIndex = ZINDEX.OVERLAY;
  document.getElementById("shield").style.zIndex = ZINDEX.SHIELD;
  document.getElementById("iconLoader").style.zIndex = ZINDEX.ICON_LOADER;
  document
    .querySelectorAll("div[id='display'] svg")
    .forEach((t) => (t.style.zIndex = ZINDEX_WITH_DISP.ICON));
}

export function zindex({ targets, setType }) {
  targets.forEach((targetWrapper) => setZindexToParts(targetWrapper));

  function setZindexToParts(targetWrapper) {
    targetWrapper.childNodes.forEach((parts) => setZindexToPart(parts));

    function setZindexToPart(target) {
      target.style.zIndex = getZindex();

      function getZindex() {
        const COMPARE_ADD_NUMBER_OF_ZINDEX = 1;
        const zindex = getZindexOriginal();
        const addZindexOfHighLight = getZindexByHighLight();
        if (isSetTypeAdd()) {
          return zindex + addZindexOfHighLight + COMPARE_ADD_NUMBER_OF_ZINDEX;
        }
        return zindex + addZindexOfHighLight - COMPARE_ADD_NUMBER_OF_ZINDEX;

        function getZindexOriginal() {
          const zindex = ZINDEX_WITH_DISP[target.dataset.zIndex];
          return Number(zindex);
        }
        function isSetTypeAdd() {
          return setType === "add";
        }
        function getZindexByHighLight() {
          if (isHighLightParts()) {
            return COMPARE_ADD_NUMBER_OF_ZINDEX;
          }
          return 0;

          function isHighLightParts() {
            const classNameByTarget = target.dataset.class;
            const classNameByHighLight = getClassNameByHighLight();
            return classNameByTarget === classNameByHighLight;

            function getClassNameByHighLight() {
              const disp = document.getElementById("display");
              return disp.dataset.highlightParts;
            }
          }
        }
      }
    }
  }
}
