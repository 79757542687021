"use strict";

import { setActiveClass } from "./display";
import { FONT_COLOR, FONT_COLOR_GEOMETRY } from "./_const";

export function initSummaryValue() {
  document
    .querySelectorAll(
      "div[id='wrapperValueLeft'] button,div[id='wrapperValueRight'] button"
    )
    .forEach((t) => {
      t.lastElementChild.classList.remove(FONT_COLOR_GEOMETRY.calculated);
      t.lastElementChild.classList.remove(FONT_COLOR_GEOMETRY.referenced);
      t.style.color = "";
      t.lastElementChild.textContent = "";
    });
}

export function setSummaryValue() {
  const thImg = (() => {
    const isCompare = document.getElementById("display").dataset.compareDisp;
    return document.querySelector(
      `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
    );
  })();
  if (!thImg) return;

  document
    .querySelectorAll(
      "div[id='wrapperValueLeft'] button,div[id='wrapperValueRight'] button"
    )
    .forEach((btn) => {
      const td = document.querySelector(
        `td[data-class='${btn.dataset.class}'][data-col-index='${thImg.dataset.colIndex}']`
      );
      btn.lastElementChild.style.color = "";
      if (td.dataset.sourceVal === "geometry") {
        btn.lastElementChild.style.color = FONT_COLOR;
      } else {
        const color = FONT_COLOR_GEOMETRY[td.dataset.sourceVal];
        btn.lastElementChild.classList.add(color);
      }
      btn.lastElementChild.textContent = td.firstElementChild.textContent;
    });
}

export function setColorToValue() {
  const t = getHighlightValue();
  if (!t) return;
  setActiveClass(t, "add", "remove");
  // t.style.color = "";
  t.firstElementChild.style.color = "";
}
export function initColorToValue() {
  const t = getHighlightValue();
  if (!t) return;
  setActiveClass(t, "remove", "add");
  // t.style.color = FONT_COLOR;
  t.firstElementChild.style.color = FONT_COLOR;
}
function getHighlightValue() {
  const hlp = document.getElementById("display").dataset.highlightParts;
  return document.querySelector(`button[data-class='${hlp}']`);
}
