"use strict";
import { getStyleValueByNumber, getDispTypeByWGTT } from "./_common";

export function setStyleToWrpDispTabTable() {
  const wdtt = document.getElementById("wrpDispTabTable");
  // getComputedで取得できる値は実際に表示されている範囲の値
  // getComputed().style：見えている範囲の値
  // target.style ：設定されている値
  const mainDisplayWidth = getWidthByStyle("display");
  const width = getWidth();
  wdtt.style.width = `${width}px`;

  function getWidthByStyle() {
    const target = document.getElementById("display");
    const widthTemp = target.style.width;
    const widthTemp2 = widthTemp.replace("px", "");
    return Number(widthTemp2);
  }
  function getWidth() {
    const scaleType = getDispTypeByWGTT();
    const result = {
      block: getWidthByBlock(),
      flex: getWidthByFlex(),
      full: getWidthByFull(),
    };
    return result[scaleType];

    function getWidthByBlock() {
      return mainDisplayWidth;
    }
    function getWidthByFlex() {
      const sizeTableWidth = getStyleValueByNumber({
        id: "wrapperSizeTable",
        item: "width",
      });
      const gap = getStyleValueByNumber({
        id: "pageCompare",
        item: "padding",
      });
      return mainDisplayWidth + sizeTableWidth + gap;
    }
    function getWidthByFull() {
      const width = getStyleValueByNumber({
        id: "wrpAds",
        item: "width",
      });
      const margin = getStyleValueByNumber({
        id: "wrpAds",
        item: "marginLeft",
      });
      return mainDisplayWidth + width + margin;
    }
  }
}
