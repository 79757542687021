"use strict";

import {
  drawBikes,
  getDrawColor,
  initBikes,
  setBikes,
  setDrawColor,
} from "./bike";
import { showTextBy } from "./contentBox";
import {
  duplicateImgDisp,
  execChangeBase,
  execChangeIndicator,
  initBikeName,
  initImgDisp,
  removeImgDisp,
  setBikeNameToDisp,
  setImgDisp,
  setWordingToDisp,
} from "./display";
import { execHighlight } from "./highlight";
import { changeScale } from "./scale";
import {
  createSizePagination,
  initSizePagination,
  setSizePagination,
} from "./sizePagination";
import {
  COL_COUNT,
  initCompareToSizeTable,
  initSizeTable,
  setBackGroundColorToRowHeader,
  setBikeDataToSizeTable,
  setCompareToSizeTable,
  setImgToSizeTable,
  setSizeTable,
  setSizeTableBy,
  setStyleToTDTH,
} from "./sizeTable";
import { initSummaryValue, setSummaryValue } from "./values";
import { setWording, setWordingAfter } from "./wording";

export const setCompareTagOfTh = () => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImgs = document.querySelectorAll(
    `th[data-class='img'][data-compare='${isCompare}']`
  );
  thImgs.forEach((t) => {
    const th = `th[data-class='compare'][data-col-index='${t.dataset.colIndex}']`;
    const btnTagSolid = document.querySelector(
      `${th} button[data-class='btnTagSolid']`
    );
    const btnTagOutLine = document.querySelector(
      `${th} button[data-class='btnTagOutLine']`
    );
    if (isExistCompareTarget(t)) {
      btnTagSolid.style.display = "";
      btnTagOutLine.style.display = "none";
    } else {
      btnTagSolid.style.display = "none";
      btnTagOutLine.style.display = "";
    }
  });
};

export const changeCompare = (thImg) => {
  if (isExistCompareTarget(thImg)) {
    removeCompare(thImg);
    // removeImgDisp(thImg.dataset.colIndex);
  } else {
    const drawColor = getDrawColor();
    if (!drawColor) {
      showTextBy(thImg, "maxCompare");
      return;
    }
    createCompare(thImg);
    setDrawColor(thImg);
  }

  initSummaryValue();
  initSizePagination();
  initSizeTable();
  initCompareToSizeTable();
  initBikeName();
  initImgDisp();

  setSummaryValue();
  setSizePagination();
  setSizeTable();
  setStyleToTDTH();
  setCompareToSizeTable();
  // setBikeNameToDisp();
  setImgDisp();
  setCompareTagOfTh();
  setCompareTagOfDisp();

  setWordingToDisp();
  setWording();
  setWordingAfter();
};

const createCompare = (thImg) => {
  // dbに接続せずthImg.dataset.bikeから情報を取得している
  const values = [JSON.parse(thImg.dataset.bike)];
  const colIndex = COL_COUNT.get();
  COL_COUNT.set(values.length);
  values[0].info.colIndex = colIndex;
  drawBikes(values);
  // imgDispとwrpImgは同じ
  const mkr = thImg.dataset.mkr;
  const mdl = thImg.dataset.mdl;
  const years = thImg.dataset.years;
  const year = thImg.dataset.year;
  const color = thImg.dataset.color;
  const imgDisp = document.querySelector(
    `div[data-class='imgDisp'][data-mkr='${mkr}'][data-mdl=${mdl}][data-year='${year}'][data-color='${color}']`
  );
  setBikeDataToSizeTable("compare", imgDisp, values);
  createSizePagination(values);
  const thImgNew = document.querySelector(
    `th[data-class='img'][data-col-index='${colIndex}']`
  );
  thImgNew.dataset.compare = "true";
  const btnTagSolid = document.querySelector(
    `th[data-class='compare'][data-col-index='${colIndex}'] button[data-class='btnTagSolid']`
  );
  btnTagSolid.style.fill = thImgNew.dataset.drawColor;

  execChangeIndicator("set");
  execChangeBase("set");
  changeScale("set");
  execHighlight();
};

const removeCompare = (thImg) => {
  // *compareアイテムはcolIdexではなくmkr.mdl.year.sizeで判別する
  const removeThImg = document.querySelector(
    `th[data-class='img'][data-mkr='${thImg.dataset.mkr}'][data-mdl='${thImg.dataset.mdl}'][data-years='${thImg.dataset.years}'][data-size='${thImg.dataset.size}'][data-compare='true']`
  );
  const query = `[data-col-index='${removeThImg.dataset.colIndex}']`;
  const positionSize = document.querySelector(
    `button[data-class='positionSize']${query}`
  );
  positionSize.remove();
  const bike = document.querySelector(`div[data-class='wrapperBike']${query}`);
  bike.remove();
  const thtds = document.querySelectorAll(`td${query},th${query}`);
  thtds.forEach((t) => t.remove());
};

export const compare = () => {
  // set status
  const disp = document.getElementById("display");
  if (disp.dataset.compareDisp === "false") {
    disp.dataset.compareDisp = "true";
  } else {
    disp.dataset.compareDisp = "false";
  }

  initSummaryValue();
  initSizePagination();
  initBikes();
  initSizeTable();
  initCompareToSizeTable();
  initBikeName();
  initImgDisp();

  setSummaryValue();
  setSizePagination();
  setBikes();
  setSizeTable();
  setStyleToTDTH();
  setBackGroundColorToRowHeader();
  setCompareToSizeTable();
  setWordingToDisp();
  setBikeNameToDisp();
  setImgDisp();

  setCompareTagOfTh();
  setCompareTagOfDisp();
  setDrawColorToCompareTag();

  setWordingToDisp();
  setWording();
  setWordingAfter();
};

export const isExistCompareTarget = (thImg) => {
  const target = document.querySelector(
    `th[data-class='img'][data-mkr='${thImg.dataset.mkr}'][data-mdl='${thImg.dataset.mdl}'][data-years='${thImg.dataset.years}'][data-size='${thImg.dataset.size}'][data-compare='true']`
  );
  if (target) {
    return true;
  } else {
    return false;
  }
};

export const setCompareTagOfDisp = () => {
  const tagOutLine = document.getElementById("btnTagOutLine");
  const tagSolid = document.getElementById("btnTagSolid");
  tagOutLine.style.display = "none";
  tagSolid.style.display = "none";

  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImg = document.querySelector(
    `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
  );
  if (!thImg) return;

  const q1 = `th[data-class='compare'][data-col-index='${thImg.dataset.colIndex}']`;
  const tagOutLine2 = document.querySelector(
    `${q1} button[data-class='btnTagOutLine']`
  );
  tagOutLine.style.display = tagOutLine2.style.display;

  const tagSolid2 = document.querySelector(
    `${q1} button[data-class='btnTagSolid']`
  );
  tagSolid.style.display = tagSolid2.style.display;
  tagSolid.style.fill = tagSolid2.style.fill;
};

export const setDrawColorToCompareTag = () => {
  const thImgs = document.querySelectorAll(
    `th[data-class='img'][data-compare='true']`
  );
  if (thImgs.length === 0) return;

  for (let i = 0; i < thImgs.length; i++) {
    const thImgCompare = thImgs[i];
    const thImgSize = document.querySelector(
      `th[data-class='img'][data-compare='false'][data-mkr='${thImgCompare.dataset.mkr}'][data-mdl='${thImgCompare.dataset.mdl}'][data-years='${thImgCompare.dataset.years}'][data-size='${thImgCompare.dataset.size}']`
    );
    if (!thImgSize) continue;
    const tagCompared = document.querySelector(
      `th[data-class='compare'][data-col-index='${thImgCompare.dataset.colIndex}'] button[data-class='btnTagSolid']`
    );
    const tagSize = document.querySelector(
      `th[data-class='compare'][data-col-index='${thImgSize.dataset.colIndex}'] button[data-class='btnTagSolid']`
    );
    tagSize.style.fill = tagCompared.style.fill;
  }
};
