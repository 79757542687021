"use strict";
import {
  getDispTypeByWGTT,
  getEventTargetsByBetween,
  getStyleValueByNumber,
  setClassBySelect,
} from "./_common";
import { FONT_COLOR, ZINDEX } from "./_const";
import { LOWER_MENU } from "./lowerMenu";
import { getActivePage, initPages, setPage } from "./page";

export function onClickNaviList(event) {
  const t0 = getEventTargetsByBetween(event).find(
    (t) => t.dataset.class === "naviList"
  );
  if (!t0) return;
  initStyleOfLists();
  setStyleToSelectedList();
  initPages();
  setPage(t0);
  if (event.currentTarget.id === "naviBlock") {
    document.getElementById("naviBlock").style.display = "none";
    // document.getElementById("overlay").style.display = "none";
  }

  function initStyleOfLists() {
    document.querySelectorAll(`li[data-class='naviList']`).forEach((t) => {
      setClassBySelect(t, "remove", "add");
      t.style.color = FONT_COLOR;
    });
  }
  function setStyleToSelectedList() {
    document
      .querySelectorAll(`li[data-page='${t0.dataset.page}']`)
      .forEach((t) => {
        setClassBySelect(t, "add", "remove");
        t.style.color = "";
      });
  }
}

export function execSetStyleToNavi() {
  const navi = document.getElementById("naviBlock");
  const dispType = getDispTypeByWGTT();
  if (dispType === "full") {
    navi.style.bottom = "20px";
    navi.style.left = `${LOWER_MENU.HEIGHT_BASE + 10}px`;
    navi.style.right = "";
  } else {
    navi.style.bottom = `${LOWER_MENU.HEIGHT_BASE + 10}px`;
    navi.style.left = "";
    navi.style.right = "20px";
  }
}

export function onClickNaviIconByOpen(event) {
  const setType =
    document.getElementById("naviBlock").style.display === "" ? "init" : "set";
  setStyleToNaviIconByOpen(setType);
}

export const setStyleToNaviIconByOpen = (setType) => {
  const naviBlock = document.getElementById("naviBlock");
  const overlay = document.getElementById("overlay");
  const btnNaviOpen = document.getElementById("btnNaviOpen");
  const page = getActivePage();

  if (setType === "init") {
    naviBlock.style.display = "none";
    naviBlock.style.zIndex = "";
    overlay.style.zIndex = ZINDEX.OVERLAY;
    overlay.style.cursor = "auto";
    btnNaviOpen.style.zIndex = ZINDEX.OVERLAY + 1;
    if (page.id === "pageCompare") overlay.style.display = "none";
  } else if (setType === "set") {
    naviBlock.style.display = "";
    naviBlock.style.zIndex = ZINDEX.OVERLAY + 3;
    overlay.style.zIndex = ZINDEX.OVERLAY + 2;
    overlay.style.cursor = "pointer";
    btnNaviOpen.style.zIndex = ZINDEX.OVERLAY + 3;
    if (page.id === "pageCompare") overlay.style.display = "";
  }
};
