"use strict";

import {
  getDispTypeByWGTT,
  getScaledValue,
  getStyleValueByNumber,
} from "./_common";

export const LOWER_MENU = {
  HEIGHT_BASE: 60,
  FONT_SIZE: 28,
  ICON_SIZE: 40,
};

export function setStyleToLowerMenu() {
  // 100vwだとスマホで反映されない
  const wrapperBtnIcon = document.getElementById("wrapperBtnIcon");
  const lowerMenu = document.getElementById("lowerMenu");
  const clientWidth = document.documentElement.clientWidth;
  const clientHeight = document.documentElement.clientHeight;
  const dispType = getDispTypeByWGTT();
  if (dispType === "full") {
    lowerMenu.style.top = "0px";
    lowerMenu.style.bottom = "";
    wrapperBtnIcon.style.width = `${LOWER_MENU.HEIGHT_BASE}px`;
    wrapperBtnIcon.style.height = `${clientHeight}px`;
    wrapperBtnIcon.style.justifyContent = "";
    wrapperBtnIcon.style.placeItems = "center";
    // wrapperBtnIcon.style.alignItems = "";
  } else {
    lowerMenu.style.top = "";
    lowerMenu.style.bottom = "0px";
    wrapperBtnIcon.style.width = `${clientWidth}px`;
    wrapperBtnIcon.style.height = `${LOWER_MENU.HEIGHT_BASE}px`;
    wrapperBtnIcon.style.justifyContent = "space-around";
    wrapperBtnIcon.style.placeItems = "";
    wrapperBtnIcon.style.alignItems = "center";
  }

  // const fontSize = getScaledValue(LOWER_MENU.FONT_SIZE);
  // const iconSize = getScaledValue(LOWER_MENU.ICON_SIZE);
  document.querySelectorAll(`div[id='wrapperBtnIcon'] button`).forEach((t) => {
    t.style.width = `${50}px`;
    t.style.height = `${50}px`;
    t.style.fontSize = `${12}px`;
    t.firstElementChild.style.with = `${30}px`;
    t.firstElementChild.style.height = `${30}px`;
  });
}
