"use strict";
import { createDeepCopy } from "./_common";
import {
  DRAW_PARTS_VALUE,
  DRAW_COLOR_COMPARE,
  isVisiblePartsValue,
  DRAW_COLOR_SIZE,
} from "./_constDrawPartsValue";
import {
  getAngleByBaHe,
  getHeightByBaAn,
  getHeightByHyAn,
  getRadian,
} from "./_math";
import {
  BIKE_BASE_X_BY_GRID_SPACE,
  BIKE_BASE_Y_BY_GRID_SPACE,
  FRAME_GRID_WEIGHT,
  GEOMETRY_GRID_COLOR,
  GEOMETRY_GRID_WEIGHT,
  GEO_SIZE,
  GRID_SPACE,
  ROUND_OF_HIGHLIGHT,
  ZINDEX_WITH_DISP,
} from "./_const";
import {
  COL_COUNT,
  getColCount,
  getEventTypeByClickSizeTable,
} from "./sizeTable";

export const BIKE_OPACITY = 0.3;

export const getDrawColor = () => {
  for (let i = 0; i < DRAW_COLOR_COMPARE.length; i++) {
    const color = DRAW_COLOR_COMPARE[i];
    const thImg = document.querySelector(
      `th[data-class='img'][data-compare='true'][data-draw-color='${color}']`
    );
    if (thImg) continue;
    return color;
  }
  return null;
};

export const setDrawColor = (thImg0) => {
  const thImgs = document.querySelectorAll(
    `th[data-class='img'][data-mkr='${thImg0.dataset.mkr}'][data-mdl='${thImg0.dataset.mdl}'][data-years='${thImg0.dataset.years}'][data-size='${thImg0.dataset.size}']`
  );
  thImgs.forEach((thImg) => {
    thImg.dataset.drawColor = getDrawColor();
    const btnTagSolid = document.querySelector(
      `th[data-class='compare'][data-col-index='${thImg.dataset.colIndex}'] button[data-class='btnTagSolid']`
    );
    btnTagSolid.style.fill = thImg.dataset.drawColor;
  });
};

export function drawBikes(valForDraw) {
  const drawColor0 = getDrawColor();
  // const drawColor = !drawColor0 ? DRAW_COLOR_SIZE : drawColor0;
  const drawColor = !drawColor0 ? DRAW_COLOR_SIZE : drawColor0;
  // const drawColor = DRAW_COLOR_SIZE;
  const gwb = document.getElementById("wrapperBikes");
  valForDraw.forEach((bikeDraw) => {
    // bikeDrawは編集されるので複製しておく
    const bikeNormal = createDeepCopy(bikeDraw);
    drawBike(bikeNormal);
  });

  function drawBike(bikeDraw) {
    const dispHeight = GEO_SIZE.height;
    const wrapperBike = createWrapperOfBike();
    drawPartsByArc(bikeDraw, wrapperBike);
    drawPartsByRect(bikeDraw, wrapperBike);
    drawPartsByLine(bikeDraw, wrapperBike);
    hideForkNotType();
    wrapperBike.style.display = "none";
    wrapperBike.style.position = "absolute";

    function createWrapperOfBike() {
      const div = document.createElement("div");
      div.dataset.class = "wrapperBike";
      div.dataset.colIndex = bikeDraw.info.colIndex;
      div.style.zIndex = 1;
      gwb.appendChild(div);
      return div;
    }
    function execDrawPartsByCreateCanvas({
      parts,
      wrapperBike,
      drawType,
      bikeDraw,
    }) {
      const cnvs = createCanvas();
      cnvs.dataset.class = parts.ori.name;
      cnvs.dataset.zIndex = parts.ori.zIdx;
      setParameterToCanvas();
      return cnvs;

      function createCanvas() {
        const cnvs = document.createElement("canvas");
        wrapperBike.appendChild(cnvs);
        return cnvs;
      }
      function setParameterToCanvas() {
        const prms = getPrms();
        settingCmnToCanvas();
        if (isDrawTypeArc()) {
          return;
        }
        setStyleToCanvas();

        function getPrms() {
          const left = getLeft();
          let prms;
          if (parts.ori.crv) {
            prms = {
              top: dispHeight - (parts.scl.top + parts.scl.crv.ofs),
              left,
              width: parts.scl.len + parts.scl.dia / 2,
              height: parts.scl.crv.ofs + parts.scl.dia,
              rPoint: parts.scl.crv.ofs + parts.scl.dia / 2,
            };
            return prms;
          }
          if (parts.ori.draw === "arc") {
            prms = {
              top: dispHeight - parts.scl.top,
              left,
              // width: parts.scl.dia,
              // height: parts.scl.dia,
              width: getWidthHeightByArcType(),
              height: getWidthHeightByArcType(),
            };
            return prms;
          }
          prms = {
            top: dispHeight - parts.scl.top,
            left,
            width: parts.scl.len,
            height: parts.scl.dia,
            rPoint: parts.scl.dia / 2,
          };
          return prms;

          // パーツ描画のオフセット
          // ジオメトリの基準となるセンターラインはオフセットしない
          // ST/DTがBBより太い場合とフレームにカーブがある場合に実行
          function getLeft() {
            if (!parts.scl.ofs) {
              return parts.scl.left;
            }
            if (parts.ori.zIdx === "LINE") {
              return parts.scl.left;
            }
            return parts.scl.left - parts.scl.ofs;
          }
          function getWidthHeightByArcType() {
            if (execIsAngle(parts.ori.name)) {
              return parts.scl.dia / 2;
            }
            return parts.scl.dia;
          }
        }
        function settingCmnToCanvas() {
          cnvs.style.zIndex = ZINDEX_WITH_DISP[cnvs.dataset.zIndex];
          cnvs.style.position = "absolute";
          cnvs.style.top = `${prms.top}px`;
          cnvs.style.left = `${prms.left}px`;
          cnvs.width = getWidth();
          cnvs.height = prms.height;

          function getWidth() {
            if (isNegativeBbDrop()) {
              return prms.width * -1;
            } else {
              return prms.width;
            }

            function isNegativeBbDrop() {
              if (parts.ori.name !== "bbDrop") {
                return false;
              }
              if (bikeDraw.line.bbDrop.scl.len > 0) {
                return false;
              }
              return true;
            }
          }
        }
        function isDrawTypeArc() {
          if (drawType === "drawPartsArc" || drawType === "drawGridsArc") {
            return true;
          }
          return false;
        }
        function setStyleToCanvas() {
          const agl = getAngle();
          cnvs.style.transform = `rotate(${agl}deg)`;
          // 回転の支点は横の位置はゼロ、縦の位置は高さ（フレームの幅 diameter）の半分（中心）、topの位置を高さ（フレーム幅）で調整することでフレーム幅の中心を軸にして回転可能になる
          cnvs.style.transformOrigin = `0px ${prms.rPoint}px`;

          function getAngle() {
            if (!parts.ori.crv) {
              if (isNegativeBbDrop()) {
                return parts.ori.agl + 180;
              } else {
                return parts.ori.agl;
              }
            }
            const base = parts.ori.len;
            const hei = parts.ori.crv.ofs;
            const agl = parts.ori.agl + getAngleByBaHe(base, hei);
            return agl;

            function isNegativeBbDrop() {
              if (
                parts.ori.name !== "bbDrop" &&
                parts.ori.name !== "frontCenter"
              ) {
                return false;
              }
              if (bikeDraw.line.bbDrop.scl.len > 0) {
                return false;
              }
              return true;
            }
          }
        }
      }
    }
    function duplicateHighlightElement({ target, wrapperBike }) {
      const name = target.dataset.class;
      if (!isVisiblePartsValue(name)) {
        return null;
      }
      const target2 = target.cloneNode(true);
      target2.dataset.class = `${name}Highlight`;
      target2.style.outline = "solid 5px transparent";
      target2.style.borderRadius = getBorderRadius();
      // target2.style.borderRadius = "5px";
      // target2.style.backgroundColor = HIGH_LIGHT_COLOR;
      // wrapperBike.appendChild(target2);
      target.parentNode.insertBefore(target2, target);
      // target.style.borderRadius = target2.style.borderRadius;
      return target2;

      function getBorderRadius() {
        if (execIsAngle(name)) {
          return `${target2.width}px ${ROUND_OF_HIGHLIGHT}px ${ROUND_OF_HIGHLIGHT}px ${ROUND_OF_HIGHLIGHT}px`;
        }
        if (name === "tireSizeRear" || name === "rimSizeRear") {
          return `${target2.width / 2}px`;
        }
        return `${ROUND_OF_HIGHLIGHT}px`;
      }
    }
    function getParameterOfLineFromParts(parts) {
      const prms = {};
      prms.strokeStyle = parts.ori.clr;
      prms.lineWidth = parts.ori.lw;
      prms.dashLine = parts.ori.dl;
      prms.dashSpace = parts.ori.ds;
      prms.startX = 0;
      prms.startY = parts.scl.dia / 2;
      prms.endX = parts.scl.len;
      prms.endY = parts.scl.dia / 2;
      if (parts.ori.crv) {
        prms.startY += parts.scl.crv.ofs;
        prms.endY += parts.scl.crv.ofs;
      }
      return prms;
    }
    function addParameterOfCrossLineToParts(prms, parts) {
      prms.startX2 = parts.scl.dia / 2;
      prms.startY2 = 0;
      prms.endX2 = parts.scl.dia / 2;
      prms.endY2 = parts.scl.dia;
    }
    function changeSettingForGrid(parts) {
      const FRAME_GRID_COLOR = "rgba(100,100,100,1)";
      const GRID_DASH_LINE = 7;
      const GRID_DASH_SPACE = 3;
      parts.ori.dl = GRID_DASH_LINE;
      parts.ori.ds = GRID_DASH_SPACE;
      parts.ori.lw = FRAME_GRID_WEIGHT;
      parts.ori.clr = FRAME_GRID_COLOR;
      parts.ori.zIdx = "GRID";
    }
    function setOffsetToTopLeft(prms, bikeDraw) {
      prms.scl.top += BIKE_BASE_Y_BY_GRID_SPACE;
      // leftは描画の基準となる位置 9マス目にBBを起点とする
      const bb = bikeDraw.arc.bottomBracket.scl.dia;
      const rwEtoC = bikeDraw.value.rearWheelEndToCenter.scl.val;
      const startPositionFromBB = rwEtoC - bb / 2 + GRID_SPACE;
      const positionGap =
        BIKE_BASE_X_BY_GRID_SPACE - (bb / 2 + startPositionFromBB);
      prms.scl.left += positionGap;
    }
    function execDrawLine(cnvs, prms) {
      const ctx = cnvs.getContext("2d");
      ctx.strokeStyle = prms.strokeStyle;
      ctx.lineWidth = prms.lineWidth;
      if (isDashLine(prms.dashLine, prms.dashSpace)) {
        ctx.setLineDash([prms.dashLine, prms.dashSpace]);
      }
      ctx.moveTo(prms.startX, prms.startY);
      ctx.lineTo(prms.endX, prms.endY);
      ctx.stroke();
    }
    function isDashLine(dashLine, dashSpace) {
      return dashLine && dashSpace;
    }
    function drawPartsByArc(bikeDraw, wrapperBike) {
      for (const key in bikeDraw.arc) {
        const parts = bikeDraw.arc[key];
        setOffsetToTopLeft(parts, bikeDraw);
        drawPartsArc(parts);
        drawGridsArc(parts);
      }

      function drawPartsArc(parts) {
        const cnvs = execDrawPartsByCreateCanvas({
          parts,
          wrapperBike,
          drawType: "drawPartsArc",
        });
        duplicateHighlightElement({ target: cnvs, wrapperBike });
        const prms = getParameterOfArcFromParts();
        drawParts(cnvs);

        function getParameterOfArcFromParts() {
          const prms = {};
          prms.strokeStyle = DRAW_PARTS_VALUE[parts.ori.name].getStrokeColor();
          prms.lineWidth = Math.round(
            DRAW_PARTS_VALUE[parts.ori.name].getLineWidth({
              parts,
              bikeDraw,
            })
          );
          prms.centerX = Math.floor(parts.scl.dia / 2);
          prms.centerY = Math.floor(parts.scl.dia / 2);
          // prms.centerX = getCenterXY();
          // prms.centerY = getCenterXY();
          prms.radius = Math.floor(parts.scl.dia / 2 - prms.lineWidth / 2); // 小数点切り捨てないと描画範囲がcanvasサイズを超えてしまう
          prms.startAgl = parts.ori.aglStart;
          prms.endAgl = parts.ori.aglEnd;
          prms.anticlockwise = false; // 円の作成方向、デフォルトは時計回り
          return prms;
        }
        function drawParts() {
          const ctx = cnvs.getContext("2d");
          // 開始角度と終了角度は右水平方向が「0」となり時計回り方向に指定
          const startRad = getRadian(prms.startAgl);
          const endRad = getRadian(prms.endAgl);
          ctx.arc(
            prms.centerX,
            prms.centerY,
            prms.radius,
            startRad,
            endRad,
            prms.anticlockwise
          );
          ctx.strokeStyle = prms.strokeStyle;
          ctx.lineWidth = prms.lineWidth;
          ctx.stroke();
        }
      }
      function drawGridsArc(parts) {
        if (isNotTire()) {
          return;
        }
        changeSettingForGrid(parts);
        const cnvs = execDrawPartsByCreateCanvas({
          parts,
          wrapperBike,
          drawType: "drawGridsArc",
        });
        const prms = getParameterOfLineFromParts(parts);
        drawGrid();

        function isNotTire() {
          if (
            parts.ori.name !== "tireSizeRear" &&
            parts.ori.name !== "tireSizeFront"
          ) {
            return true;
          }
          return false;
        }
        function drawGrid() {
          addParameterOfCrossLineToParts(prms, parts);
          const ctx = cnvs.getContext("2d");
          ctx.strokeStyle = prms.strokeStyle;
          ctx.lineWidth = prms.lineWidth;
          if (isDashLine(prms.dashLine, prms.dashSpace)) {
            ctx.setLineDash([prms.dashLine, prms.dashSpace]);
          }
          ctx.moveTo(prms.startX, prms.startY);
          ctx.lineTo(prms.endX, prms.endY);
          ctx.moveTo(prms.startX2, prms.startY2);
          ctx.lineTo(prms.endX2, prms.endY2);
          ctx.stroke();
        }
      }
    }
    function drawPartsByRect(bikeDraw, wrapperBike) {
      for (const key in bikeDraw.rect) {
        const parts = bikeDraw.rect[key];
        setOffsetToTopLeft(parts, bikeDraw);
        drawPartsRect(parts);
        drawGridsRect(parts);
      }

      function drawPartsRect(parts) {
        const cnvs = execDrawPartsByCreateCanvas({
          parts,
          wrapperBike,
          drawType: "drawPartsRect",
        });
        duplicateHighlightElement({ target: cnvs, wrapperBike });
        setParameterOfRectToParts();
        const prms = getParameterOfRectFromParts(parts);
        drawParts();

        function setParameterOfRectToParts() {
          parts.ori.clr = drawColor;
        }
        function getParameterOfRectFromParts(parts) {
          const prmsReturn = {};
          const prmsCalculate = getPrmsCalculate();
          const eventType = getEventType();
          const func = {
            nonTapered: setParameterByNonTapered,
            normalTapered: setParameterByNormalTapered,
            reverseTapered: setParameterByReverseTapered,
            curve: setParameterByCurve,
          };
          func[eventType]();
          setParameterByCommon();
          return prmsReturn;

          function getPrmsCalculate() {
            const prms = {};
            prms.top = 0;
            prms.btm = parts.scl.dia;
            prms.left = 0;
            prms.right = parts.scl.len;
            const taperedAbsValue = Math.abs(parts.ori.tpr);
            prms.tprHei = parts.scl.dia * taperedAbsValue;
            prms.tprOfs = (parts.scl.dia - prms.tprHei) / 2;
            return prms;
          }
          function getEventType() {
            if (parts.ori.crv) {
              return "curve";
            }
            if (isNonTapered(parts.ori.tpr)) {
              return "nonTapered";
            }
            if (isNormalTapered(parts.ori.tpr)) {
              return "normalTapered";
            }
            if (isReverseTapered(parts.ori.tpr)) {
              return "reverseTapered";
            }

            function isNonTapered(tapered) {
              return tapered === 1;
            }
            function isNormalTapered(tapered) {
              return tapered > 0;
            }
            function isReverseTapered(tapered) {
              return tapered < 0;
            }
          }
          function setParameterByNonTapered() {
            // 1-----4
            // |     |
            // 2-----3
            prmsReturn.x1 = prmsCalculate.left;
            prmsReturn.y1 = prmsCalculate.top;
            prmsReturn.x2 = prmsCalculate.left;
            prmsReturn.y2 = prmsCalculate.btm;
            prmsReturn.x3 = prmsCalculate.right;
            prmsReturn.y3 = prmsCalculate.btm;
            prmsReturn.x4 = prmsCalculate.right;
            prmsReturn.y4 = prmsCalculate.top;
          }
          function setParameterByNormalTapered() {
            prmsReturn.x1 = prmsCalculate.left;
            prmsReturn.y1 = prmsCalculate.top;
            prmsReturn.x2 = prmsCalculate.left;
            prmsReturn.y2 = prmsCalculate.btm;
            prmsReturn.x3 = prmsCalculate.right;
            prmsReturn.y3 = prmsCalculate.tprHei + prmsCalculate.tprOfs;
            prmsReturn.x4 = prmsCalculate.right;
            prmsReturn.y4 = prmsCalculate.tprOfs;
          }
          function setParameterByReverseTapered() {
            prmsReturn.x1 = prmsCalculate.left;
            prmsReturn.y1 = prmsCalculate.tprOfs;
            prmsReturn.x2 = prmsCalculate.left;
            prmsReturn.y2 = prmsCalculate.tprHei + prmsCalculate.tprOfs;
            prmsReturn.x3 = prmsCalculate.right;
            prmsReturn.y3 = prmsCalculate.btm;
            prmsReturn.x4 = prmsCalculate.right;
            prmsReturn.y4 = prmsCalculate.top;
          }
          function setParameterByCurve() {
            // 1-----6(1)-----5(4)
            // |       |      |
            // 2-----3(2)-----4(3)
            const ofsY = parts.scl.crv.ofs;
            const curve = getPrmsCurve(parts);
            const posi = getPosi(curve, parts);
            prmsReturn.x1 = prmsCalculate.left;
            prmsReturn.y1 = prmsCalculate.top + ofsY;
            prmsReturn.x2 = prmsCalculate.left;
            prmsReturn.y2 = prmsCalculate.btm + ofsY;
            prmsReturn.x3 = posi.x2;
            prmsReturn.y3 = posi.y2;
            prmsReturn.x4 = posi.x3End;
            prmsReturn.y4 = posi.y3End;
            prmsReturn.x5 = posi.x4End;
            prmsReturn.y5 = posi.y4End;
            prmsReturn.x6 = posi.x1;
            prmsReturn.y6 = posi.y1;

            function getPrmsCurve(parts) {
              return {
                left: parts.scl.crv.pnt,
                right: parts.scl.crv.pnt + parts.scl.crv.len,
                len: parts.scl.crv.len,
                agl: parts.ori.crv.agl,
              };
            }
            function getPosi(curve, parts) {
              const tprAgl = getAngleByBaHe(
                parts.scl.len,
                prmsCalculate.tprOfs
              );
              const tprOfsL = getHeightByBaAn(curve.left, tprAgl);
              const tprOfsR = getHeightByBaAn(curve.right, tprAgl);
              const tprHeiR = parts.scl.dia - tprOfsR * 2;
              const endWid = getHeightByHyAn(tprHeiR / 2, curve.agl);
              return {
                x1: curve.left,
                y1: prmsCalculate.top + ofsY + tprOfsL,
                x2: curve.left,
                y2: prmsCalculate.btm + ofsY - tprOfsL,
                x3: curve.right,
                y3: prmsCalculate.btm + ofsY - tprOfsR,
                x4: curve.right,
                y4: prmsCalculate.top + ofsY + tprOfsR,
                x3End: curve.right + endWid,
                // y3End: btm - endHei, // 28  8.4 = 19.6
                y3End: tprHeiR + tprOfsR,
                x4End: curve.right - endWid,
                // y4End: top + endHei, // 8.3
                y4End: tprOfsR,
              };
            }
          }
          function setParameterByCommon() {
            prmsReturn.fillStyle = parts.ori.clr;
          }
        }
        function drawParts() {
          // 1-----6(1)-----5(4)
          // |       |      |
          // 2-----3(2)-----4(3)
          const ctx = cnvs.getContext("2d");
          const radius = 2.5;
          const radius2 = 3.5;
          const rectType = getRectType();
          ctx.moveTo(prms.x1, prms.y1);
          ctx.arcTo(prms.x2, prms.y2, prms.x3, prms.y3, radius);
          ctx.arcTo(prms.x3, prms.y3, prms.x4, prms.y4, radius);
          if (rectType === "straight") {
            ctx.arcTo(prms.x4, prms.y4, prms.x1, prms.y1, radius);
          }
          if (rectType === "curve") {
            // フォークの先端、細いのでradiusは少し大きめ
            ctx.arcTo(prms.x4, prms.y4, prms.x5, prms.y5, radius2);
            ctx.arcTo(prms.x6, prms.y6, prms.x1, prms.y1, radius2);
          }
          ctx.arcTo(prms.x1, prms.y1, prms.x2, prms.y2, radius);
          ctx.fillStyle = prms.fillStyle;
          ctx.fill();

          function getRectType() {
            if ("x5" in prms) {
              return "curve";
            }
            return "straight";
          }
        }
      }
      function drawGridsRect(parts) {
        changeSettingForGrid(parts);
        const cnvs = execDrawPartsByCreateCanvas({
          parts,
          wrapperBike,
          drawType: "drawGridsRect",
        });
        const prms = getParameterOfLineFromParts(parts);
        drawGrid();

        function drawGrid() {
          addParameterOfCrossLineToParts(prms, parts);
          execDrawLine(cnvs, prms);
        }
      }
    }
    function drawPartsByLine(bikeDraw, wrapperBike) {
      for (const key in bikeDraw.line) {
        const parts = bikeDraw.line[key];
        setOffsetToTopLeft(parts, bikeDraw);
        drawPartsLine(parts);
        drawGridsLine(parts);
      }

      function drawPartsLine(parts) {
        if (
          parts.ori.name === "effectiveTopTubeGridST" ||
          parts.ori.name === "effectiveTopTubeGridHT"
        ) {
          return;
        }
        const cnvs = execDrawPartsByCreateCanvas({
          parts,
          wrapperBike,
          drawType: "drawPartsLine",
          bikeDraw,
        });
        duplicateHighlightElement({ target: cnvs, wrapperBike });
        setParameterOfLineToParts();
        const prms = getParameterOfLineFromParts(parts);
        drawParts();

        function setParameterOfLineToParts() {
          parts.ori.clr = GEOMETRY_GRID_COLOR;
          parts.ori.lw = GEOMETRY_GRID_WEIGHT;
        }
        function drawParts() {
          addParameterOfPartsToParts();
          execDrawLineWithEnd();

          function addParameterOfPartsToParts() {
            const len = getLen();
            prms.startX2 = 0;
            prms.startY2 = 0;
            prms.endX2 = 0;
            prms.endY2 = parts.scl.dia;
            prms.startX3 = len;
            prms.startY3 = 0;
            prms.endX3 = len;
            prms.endY3 = parts.scl.dia;

            function getLen() {
              if (isNegativeBbDrop()) {
                return parts.scl.len * -1;
              } else {
                return parts.scl.len;
              }

              function isNegativeBbDrop() {
                if (parts.ori.name !== "bbDrop") {
                  return false;
                }
                if (bikeDraw.line.bbDrop.scl.len > 0) {
                  return false;
                }
                return true;
              }
            }
          }
          function execDrawLineWithEnd() {
            const GEOMETRY_GRID_OFFSET = FRAME_GRID_WEIGHT / 2;
            // 線は幅の中心を軸に描画される
            // y:0、width:2ならy:-1になる
            const ctx = cnvs.getContext("2d");
            ctx.strokeStyle = prms.strokeStyle;
            ctx.lineWidth = prms.lineWidth;
            if (isDashLine(prms.dashLine, prms.dashSpace)) {
              ctx.setLineDash([prms.dashLine, prms.dashSpace]);
            }
            ctx.moveTo(prms.startX, prms.startY);
            ctx.lineTo(prms.endX, prms.endY);
            ctx.moveTo(prms.startX2 + GEOMETRY_GRID_OFFSET, prms.startY2);
            ctx.lineTo(prms.endX2 + GEOMETRY_GRID_OFFSET, prms.endY2);
            ctx.moveTo(prms.startX3 - GEOMETRY_GRID_OFFSET, prms.startY3);
            ctx.lineTo(prms.endX3 - GEOMETRY_GRID_OFFSET, prms.endY3);
            ctx.stroke();
          }
        }
      }
      function drawGridsLine(parts) {
        if (isNotTarget()) {
          return;
        }
        changeSettingForGrid(parts);
        const cnvs = execDrawPartsByCreateCanvas({
          parts,
          wrapperBike,
          drawType: "drawGridsLine",
        });
        if (parts.ori.name === "wheelBase") {
          setStyleForWheelBase();
        }
        const prms = getParameterOfLineFromParts(parts);
        drawGrid();

        function isNotTarget() {
          if (
            parts.ori.name === "effectiveTopTubeGridST" ||
            parts.ori.name === "effectiveTopTubeGridHT" ||
            parts.ori.name === "wheelBase"
          ) {
            return false;
          }
          return true;
        }
        function setStyleForWheelBase() {
          const bbdLen = bikeDraw.line.bbDrop.scl.len;
          const bbdTop = bikeDraw.line.bbDrop.scl.top;
          const height = bbdTop + bbdLen;
          cnvs.style.top = `${
            dispHeight - BIKE_BASE_Y_BY_GRID_SPACE - height
          }px`;
        }
        function drawGrid() {
          addParameterOfCrossLineToParts(prms, parts);
          execDrawLine(cnvs, prms);
        }
      }
    }
    function hideForkNotType() {
      const forkName = {
        straight: "forkCurve",
        curve: "forkStraight",
      };
      wrapperBike
        .querySelectorAll(
          `canvas[data-class='${
            forkName[bikeDraw.info.forkType]
          }'][data-z-index='FRAME']`
        )
        .forEach((t) => (t.style.display = "none"));
    }
  }
}

export function execIsAngle(name) {
  return name === "headTubeAngle" || name === "seatTubeAngle";
}

export const removeBikes = () => {
  const thImgs = document.querySelectorAll(
    `th[data-class='img'][data-compare='false']`
  );
  thImgs.forEach((thImg) => {
    const bikes = document.querySelectorAll(
      `div[data-class='wrapperBike'][data-col-index='${thImg.dataset.colIndex}']`
    );
    bikes.forEach((t) => t.remove());
  });
};

export const initBikes = () => {
  const wrpBikes = document.querySelectorAll(`div[data-class='wrapperBike']`);
  wrpBikes.forEach((t) => {
    t.style.display = "none";
    t.style.opacity = 1;
    t.style.zIndex = 1;
  });
};

export const setBikes = () => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  if (isCompare === "true") {
    const thImgs = document.querySelectorAll(
      `th[data-class='img'][data-compare='true']`
    );
    thImgs.forEach((thImg) => {
      const bike = document.querySelector(
        `div[data-class='wrapperBike'][data-col-index='${thImg.dataset.colIndex}']`
      );
      bike.style.display = "";
      if (thImg.dataset.active === "false") {
        bike.style.opacity = 0.5;
      } else {
        bike.style.zIndex = 2;
      }
    });
  } else {
    const thImg = document.querySelector(
      `th[data-class='img'][data-active='true'][data-compare='false']`
    );
    if (!thImg) return;
    const bike = document.querySelector(
      `div[data-class='wrapperBike'][data-col-index='${thImg.dataset.colIndex}']`
    );
    bike.style.display = "";
  }
};
