"use strict";
import {
  getEventTargetsByBetween,
  getQueryByNames,
  getScaledValue,
  getYears,
  replaceName,
  setClassToIconByLight,
} from "./_common";
import { BORDER_X2, GEO_SIZE, GRID_SPACE, NAMES } from "./_const";
import { execHighlight } from "./highlight";
import { showFullImgBy, showFullImgByBike } from "./imgsByYear";
import { isComparePage } from "./page";
import { changeScale } from "./scale";
import { getThImg } from "./sizeTable";
import { createSvgOfMagnify } from "./svg";
import { getWordingName, getWordingNameByAll } from "./wording";

export const BTN_STYLE = {
  width: 40,
  height: 40,
  // gap: 12,
};

export const WRP_BTN_ICON_STYLE = {
  width: BTN_STYLE.width * 6, // +1で
  height: BTN_STYLE.height,
};

export const WRAPPER_TITLE_STYLE = {
  width: GEO_SIZE.width - BORDER_X2,
  height: BTN_STYLE.height + BTN_STYLE.gap,
};

export function execCreateDispGrid() {
  console.log("createBikeDisplay");
  const DISP_GRID_COLOR = "rgb(230,230,230)";
  const GRID_WIDTH = 1; // 線の太さはキャンバスのサイズと合わせない場合に奇数で設定すると表示がぼやけたり色がおかしくなる
  const cnvs = document.getElementById("gridWhole");
  const ctx = cnvs.getContext("2d");
  ctx.strokeStyle = DISP_GRID_COLOR;
  ctx.beginPath();
  setContextGridX(ctx);
  setContextGridY(ctx);
  ctx.stroke();

  function setContextGridX(ctx) {
    setContextGrid({
      ctx,
      widthHeight: cnvs.height,
      prms: getSettingX(),
    });

    function getSettingX() {
      return {
        startX: 0,
        endX: cnvs.width,
        gridSpaceX: 0,
        startY: 0,
        endY: 0,
        gridSpaceY: GRID_SPACE,
        lineWidth: GRID_WIDTH,
      };
    }
  }
  function setContextGridY(ctx) {
    setContextGrid({
      ctx,
      widthHeight: cnvs.width,
      prms: getSettingY(),
    });

    function getSettingY() {
      return {
        startX: 0,
        endX: 0,
        gridSpaceX: GRID_SPACE,
        startY: 0,
        endY: cnvs.height,
        gridSpaceY: 0,
        lineWidth: GRID_WIDTH,
      };
    }
  }
  function setContextGrid({ ctx, widthHeight, prms }) {
    // offsetPrmsFor1pxLineをしないとグリッドの色変更がうまくできない
    offsetPrmsFor1pxLine(prms);
    const length = Math.ceil(widthHeight / GRID_SPACE);
    ctx.lineWidth = prms.lineWidth;
    for (let i = 1; i < length; i++) {
      // i=0 // 最初のgridは不要
      const startX = prms.startX + i * prms.gridSpaceX;
      const startY = prms.startY + i * prms.gridSpaceY;
      const endX = prms.endX + i * prms.gridSpaceX;
      const endY = prms.endY + i * prms.gridSpaceY;
      ctx.moveTo(startX, startY);
      ctx.lineTo(endX, endY);
    }

    function offsetPrmsFor1pxLine(prms) {
      // 線の太さが奇数の場合、描画の位置の小数点第一位を0.5pxにすることで線を正しく描画できる
      const keys = [
        "startX",
        "startY",
        "endX",
        "endY",
        "startX2",
        "startY2",
        "endX2",
        "endY2",
        "startX3",
        "startY3",
        "endX3",
        "endY3",
      ];
      const offset = 0.5;
      for (const key of keys) {
        addOffsetValue(key);
      }

      function addOffsetValue(key) {
        if (!(key in prms)) {
          return;
        }
        prms[key] += offset;
      }
    }
  }
}

export function offsetToDispGrid() {
  // BIKE_BASE_Y_BY_GRID_SPACEとGEO_SIZE.widthの小数点の値に差がある場合にオフセットさせる
  // *GRID_SPACEで割り切れない場合
  const gc = document.getElementById("gridWhole");
  gc.style.left = `${-(GRID_SPACE * 0.5)}px`;
}

export function onClickBase(event) {
  if (!isComparePage()) return;
  const t = getEventTargetsByBetween(event).find(
    (t) => t.tagName.toLowerCase() === "button"
  );
  if (!t) return;
  execChangeBase("change");
  changeScale("change");
  execHighlight();
  setStyleToBikeName();
  setStyleToWrpImgDisp();
  // const span = t.lastElementChild;
  // if (span.textContent === "Base Ground") {
  //   span.textContent = "Base Frame";
  // } else {
  //   span.textContent = "Base Ground";
  // }
}

export function execChangeBase(setType) {
  const disp = document.getElementById("display");
  if (setType === "change") {
    if (disp.dataset.base === "ground") {
      disp.dataset.base = "bb";
    } else {
      disp.dataset.base = "ground";
    }
  }
  const getTargets = () => {
    const q1 = execGetQueryByBase();
    const t1 = document.querySelectorAll(q1);
    const indicator = disp.dataset.indicator;
    if (indicator === "bike_grid_line_val" || indicator === "bike_grid_line") {
      return { t1, t2: null };
    } else if (indicator === "bike_grid") {
      return {
        t1,
        t2: [...t1].filter((t) => {
          return t.dataset.zIndex === "LINE";
        }),
      };
    } else if (indicator === "bike") {
      return {
        t1,
        t2: [...t1].filter((t) => {
          return t.dataset.zIndex === "LINE" || t.dataset.zIndex === "GRID";
        }),
      };
    }
  };

  const targets = getTargets();
  if (disp.dataset.base === "ground") {
    targets.t1.forEach((t) => (t.style.display = ""));
    if (targets.t2) {
      targets.t2.forEach((t) => (t.style.display = "none"));
    }
  } else {
    targets.t1.forEach((t) => (t.style.display = "none"));
  }
}

export function execGetQueryByBase() {
  const arrayQuery = [
    "canvas[data-class='tireSizeRear']",
    "canvas[data-class='tireSizeFront']",
    "canvas[data-class='rimSizeRear']",
    "canvas[data-class='rimSizeFront']",
    "canvas[data-class='standOver']",
    "canvas[data-class='bbHeight']",
  ];
  return arrayQuery.toString();
}

export function execSetStyleToMainDisplay() {
  const md = document.getElementById("display");
  // const dispScaled = document.getElementById("displayScaled");
  md.style.width = `${getScaledValue(GEO_SIZE.width)}px`;
  md.style.height = `${getScaledValue(GEO_SIZE.height)}px`;

  // const wtbHeight = getStyleValueByNumber({
  //   id: "lowerMenu",
  //   item: "height",
  // });
  // dispScaled.style.top = `${getScaledValue(-wtbHeight)}px`;
}

export function onClickIndicator(event) {
  if (!isComparePage()) return;
  const t = getEventTargetsByBetween(event).find(
    (t) => t.tagName.toLowerCase() === "button"
  );
  if (!t) return null;
  execChangeIndicator("change");
  execHighlight();
  // const span = t.lastElementChild;
  // if (span.textContent === "Hide Line") {
  //   span.textContent = "Show Line";
  // } else {
  //   span.textContent = "Hide Line";
  // }
}

export function execChangeIndicator(setType) {
  const disp = document.getElementById("display");
  const indicator = getIndicator();
  changeIndicator();
  setIndicatorStateToDisp();

  function getIndicator() {
    const indicator = disp.dataset.indicator;
    // const mapIndicator = {
    //   bike_grid_line_val: "bike",
    //   bike: "bike_grid",
    //   bike_grid: "bike_grid_line",
    //   bike_grid_line: "bike_grid_line_val",
    // };
    const mapIndicator = {
      bike_grid_line_val: "bike",
      bike: "bike_grid_line_val",
      // bike_grid: "bike_grid_line",
      // bike_grid_line: "bike_grid_line_val",
    };
    if (setType === "change") {
      return mapIndicator[indicator];
    } else if (setType === "set") {
      return indicator;
    }
  }
  function changeIndicator() {
    const ts = getTargets();
    const map = {
      bike: {
        GRID: "none",
        LINE: "none",
        VALUE: "none",
      },
      bike_grid: {
        GRID: "",
        LINE: "none",
        VALUE: "none",
      },
      bike_grid_line: {
        GRID: "",
        LINE: "",
        VALUE: "none",
      },
      bike_grid_line_val: {
        GRID: "",
        LINE: "",
        VALUE: "grid",
      },
    };
    const keys = ["GRID", "LINE", "VALUE"];
    // const keys = ["GRID", "LINE"];
    keys.forEach((key) => {
      const disp = map[indicator][key];
      ts[key].forEach((t) => (t.style.display = disp));
    });
  }
  function getTargets() {
    const qNot = getQueryByBase();
    const q1 = "button[data-z-index='VALUE']";
    const q2 = "canvas[data-z-index='LINE']";
    const q3 = "canvas[data-z-index='GRID']";
    // return document.querySelectorAll(`${q1},${q2},${q3}`);
    return {
      VALUE: document.querySelectorAll(`${q1}`),
      LINE: document.querySelectorAll(`${q2}${qNot}`),
      GRID: document.querySelectorAll(`${q3}${qNot}`),
    };
  }
  function getQueryByBase() {
    if (disp.dataset.base === "ground") {
      return "";
    } else if (disp.dataset.base === "bb") {
      const query = execGetQueryByBase();
      return `:not(${query.toString()})`;
    }
  }
  function setIndicatorStateToDisp() {
    if (setType === "set") {
      return;
    }
    disp.dataset.indicator = indicator;
  }
}

export function setInfoToDisp(val, setType) {
  document.getElementById("smMakerModel").dataset[setType] = val;
}

export function setActiveClass(t, fncA, fncB) {
  t.classList[fncA]("border-emerald-400");
  t.classList[fncA]("border-[3px]");
  t.classList[fncA]("font-semibold");
  t.classList[fncA]("text-emerald-400");
  t.classList[fncB]("border");
  t.classList[fncB]("border-neutral-300");
  t.classList[fncB]("hover:border-neutral-700");
  t.classList[fncB]("hover:text-neutral-700");
}

export function createGridBase() {
  const cnvs = document.getElementById("gridBaseX");
  const ctx = cnvs.getContext("2d");
  ctx.strokeStyle = "rgb(255,200,200)";
  ctx.lineWidth = 2;
  ctx.moveTo(1, 1);
  ctx.lineTo(cnvs.width, 1);
  ctx.stroke();

  const cnvs2 = document.getElementById("gridBaseY");
  const ctx2 = cnvs2.getContext("2d");
  ctx2.strokeStyle = "rgb(255,200,200)";
  ctx2.lineWidth = 2;
  ctx2.moveTo(1, 1);
  ctx2.lineTo(1, cnvs2.height);
  ctx2.stroke();
}

export const initBikeName = () => {
  const bikeName = document.getElementById("bikeName");
  bikeName.textContent = "";
};

export const setWordingToDisp = () => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImg = document.querySelector(
    `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
  );
  if (!thImg) return;

  const mkr = getWordingNameByAll(thImg.dataset.mkr);
  const mdl = getWordingNameByAll(thImg.dataset.mdl);
  const years = getYears(thImg.dataset.years);
  const bikeName = document.getElementById("bikeName");
  bikeName.dataset.wordingEng = `${mkr.eng}  ${mdl.eng}  ${years}  ${thImg.dataset.size}`;
  bikeName.dataset.wordingJpn = `${mkr.jpn}  ${mdl.jpn}  ${years}  ${thImg.dataset.size}`;
};

export const setBikeNameToDisp = () => {
  const bikeName = document.getElementById("bikeName");
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImg = document.querySelector(
    `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
  );
  if (!thImg) {
    bikeName.textContent = "";
    return;
  }

  const lang = document.getElementById("display").dataset.lang;
  const map = { eng: "wordingEng", jpn: "wordingJpn" };
  bikeName.textContent = bikeName.dataset[map[lang]];
};

export const setStyleToBikeName = () => {
  const display = document.getElementById("display");
  const base = display.dataset.base;
  const bikeName = document.getElementById("bikeName");
  const map = {
    ground: { top: 10, left: 262, width: 410, height: 50 },
    // bb: { top: 10, left: 84, width: 170, height: 76 },
    bb: { top: 124, left: 84, width: 170, height: 76 },
  };
  bikeName.style.top = `${map[base].top}px`;
  bikeName.style.left = `${map[base].left}px`;
  bikeName.style.width = `${map[base].width}px`;
  bikeName.style.height = `${map[base].height}px`;
};

export const setStyleToWrpImgDisp = () => {
  // const display = document.getElementById("display");
  // const base = display.dataset.base;
  const base = "ground";
  const wrpImgDisp = document.getElementById("wrpImgDisp");
  const map = {
    ground: { top: 10, left: 84, width: 170, height: 110 },
    bb: { top: 90, left: 84, width: 170, height: 110 },
  };
  wrpImgDisp.style.top = `${map[base].top}px`;
  wrpImgDisp.style.left = `${map[base].left}px`;
  wrpImgDisp.style.width = `${map[base].width}px`;
  wrpImgDisp.style.height = `${map[base].height}px`;
};

export const duplicateImgDisp = (wrpImg) => {
  const mkr = wrpImg.parentElement.dataset.mkr;
  const mdl = wrpImg.parentElement.dataset.mdl;
  const years = wrpImg.parentElement.dataset.years;
  const year = wrpImg.parentElement.dataset.year;
  const color = wrpImg.parentElement.dataset.color;
  const urlTmb = wrpImg.parentElement.dataset.urlTmb;
  const urlImg = wrpImg.parentElement.dataset.urlImg;
  const imgDisp = document.querySelector(
    `div[data-class='imgDisp'][data-mkr='${mkr}'][data-mdl='${mdl}'][data-year='${year}'][data-color='${color}']`
  );
  if (imgDisp) return;

  const wrpImgDisp = document.getElementById("wrpImgDisp");
  const cloneWrpImg = wrpImg.cloneNode(true);
  cloneWrpImg.dataset.class = "imgDisp";
  cloneWrpImg.dataset.mkr = mkr;
  cloneWrpImg.dataset.mdl = mdl;
  cloneWrpImg.dataset.years = years;
  cloneWrpImg.dataset.year = year;
  cloneWrpImg.dataset.color = color;
  cloneWrpImg.dataset.urlTmb = urlTmb;
  cloneWrpImg.dataset.urlImg = urlImg;
  cloneWrpImg.style.width = "";
  cloneWrpImg.style.height = "";
  cloneWrpImg.style.margin = "";
  cloneWrpImg.style.display = "none";
  wrpImgDisp.appendChild(cloneWrpImg);
};

// export const removeImgDisp = (colIndex) => {
//   const imgDisp = document.querySelector(
//     `div[data-class='imgDisp'][data-col-index='${colIndex}']`
//   );
//   if (!imgDisp) return;
//   imgDisp.remove();
// };

export const initImgDisp = () => {
  const imgDisps = document.querySelectorAll(`div[data-class='imgDisp']`);
  imgDisps.forEach((t) => (t.style.display = "none"));
};

export const setImgDisp = () => {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImg = document.querySelector(
    `th[data-class='img'][data-active='true'][data-compare='${isCompare}']`
  );
  if (!thImg) return;
  const mkr = thImg.dataset.mkr;
  const mdl = thImg.dataset.mdl;
  const years = thImg.dataset.years;
  const year = thImg.dataset.year;
  const color = thImg.dataset.color;
  const imgDisp = document.querySelector(
    `div[data-class='imgDisp'][data-mkr='${mkr}'][data-mdl=${mdl}][data-year='${year}'][data-color='${color}']`
  );
  if (!imgDisp) return;
  imgDisp.style.display = "";
};

export function onClickImgOfColorsByDisplay(event) {
  const imgDisp = getEventTargetsByBetween(event).find(
    (t) => t.dataset.class === "imgDisp"
  );
  if (!imgDisp) return null;

  // const t2 = document.querySelector(
  //   `div[data-class='imgDisp']:not([style = 'display: none;'])`
  // );
  // if (!t2) return null;

  showFullImgByBike(imgDisp);
}
