"use strict";

export function getRadian(deg) {
  return deg * (Math.PI / 180);
}

export function getDegree(rad) {
  return rad * (180 / Math.PI);
}

export function getBaseByHyAn(hyp, deg) {
  const rad = getRadian(deg);
  return hyp * Math.cos(rad);
}

export function getHeightByHyAn(hyp, deg) {
  const rad = getRadian(deg);
  return hyp * Math.sin(rad);
}

export function getHeightByBaAn(base, deg) {
  const rad = getRadian(deg);
  return base * Math.tan(rad);
}

export function getAngleByBaHe(base, hei) {
  const rad = Math.atan(hei / base);
  return getDegree(rad);
}

export function getHypotenuseByBaAn(base, deg) {
  const rad = getRadian(deg);
  return base / Math.cos(rad);
}

export function mathRoundSetDecimalPlace(num, place) {
  // 整数化して割る
  let result;
  const pow = Math.pow(10, place);
  result = num * pow;
  result = Math.round(result);
  return result / pow;
}
