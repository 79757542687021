"use strict";
import { getEventTargetsByBetween } from "./_common";
import { initSummaryValue, setSummaryValue } from "./values";
import {
  changeSizeTable,
  getEventTypeByClickSizeTable,
  initCompareToSizeTable,
  initSizeTable,
  setBikeNameToThNameHeader,
  setCompareToSizeTable,
  setSizeTable,
  setSizeTableBy,
  setStyleToTDTH,
} from "./sizeTable";
import {
  execChangeBase,
  execChangeIndicator,
  initBikeName,
  initImgDisp,
  setBikeNameToDisp,
  setImgDisp,
  setWordingToDisp,
} from "./display";
import { BIKE_OPACITY, initBikes, removeBikes, setBikes } from "./bike";
import { execHighlight } from "./highlight";
import { changeScale } from "./scale";
import {
  changeSizePagination,
  initSizePagination,
  setSizePagination,
} from "./sizePagination";
import { setCompareTagOfDisp, setTags } from "./compare";
import { setWording, setWordingAfter } from "./wording";

export function changeBikeSize(thImg) {
  console.log(`changeBikeSize`);
  // set status
  if (thImg.dataset.active === "true") {
    // chancel
    thImg.dataset.active = "false";
  } else {
    // change
    const isCompare = document.getElementById("display").dataset.compareDisp;
    const thImgs = document.querySelectorAll(
      `th[data-class='img'][data-compare='${isCompare}']`
    );
    thImgs.forEach((t) => (t.dataset.active = "false"));
    thImg.dataset.active = "true";
  }

  initBikes();
  initSizePagination();
  initSummaryValue();
  initBikeName();
  initImgDisp();
  initCompareToSizeTable();
  initSizeTable();

  setBikes();
  setSizeTable();
  setStyleToTDTH();
  setCompareToSizeTable();
  setSizePagination();
  setSummaryValue();
  setWordingToDisp();
  // setBikeNameToDisp();
  setImgDisp();
  setCompareTagOfDisp();
  setWording();
  setWordingAfter();
  // execSetWholeNosToLocalStorage();
  // execSetWholeNosToUrl();
  execChangeIndicator("set");
  execChangeBase("set");
  changeScale("set");
  execHighlight();
}
