"use strict";

import {
  changeWindowByFlexOrBlock,
  getDispTypeByWGTT,
  getFontSizeByTarget,
  getScaledValue,
} from "./_common";
import { ZINDEX } from "./_const";
import { setStyleToWrpBtnsInLowerMenu } from "./lowerMenu";
import {
  getContentHeightOfPage,
  getContentWidthOfPage,
  resize,
} from "./resize";

export function execSetStyleToPages() {
  document
    .querySelectorAll("article[data-class='page']")
    .forEach((page) => setStyleToPage(page));

  function setStyleToPage(page) {
    const PAGE_PADDING = 12;
    const padding = getScaledValue(PAGE_PADDING);
    page.style.padding = `${padding}px`;
  }
}

export function initPages() {
  const pages = document.querySelectorAll("article[data-class='page']");
  pages.forEach((t) => (t.dataset.active = "false"));
  pages.forEach((t) => (t.style.display = "none"));
}

export function setPage(target) {
  const page = document.getElementById(target.dataset.page);
  page.dataset.active = "true";
  page.style.display = "";

  const overlay = document.getElementById("overlay");
  const main = document.getElementById("main");
  const btnNaviOpen = document.getElementById("btnNaviOpen");

  if (page.id === "pageCompare") {
    main.style.zIndex = "";
    overlay.style.zIndex = ZINDEX.OVERLAY;
    overlay.style.display = "none";
    overlay.style.cursor = "auto";
    btnNaviOpen.style.zIndex = "";
    const dispType = getDispTypeByWGTT();
    changeWindowByFlexOrBlock(dispType);
    resize();
    return;
  }

  main.style.zIndex = ZINDEX.OVERLAY + 1;
  overlay.style.zIndex = ZINDEX.OVERLAY;
  overlay.style.display = "";
  overlay.style.cursor = "auto";
  btnNaviOpen.style.zIndex = ZINDEX.OVERLAY + 1;
}

export const isComparePage = () => {
  const page = getActivePage();
  if (!page) return false;
  return page.id === "pageCompare";
};

export const getActivePage = () => {
  return document.querySelector(
    `article[data-class='page'][data-active='true']`
  );
};

export const setStyleToPageContext = () => {
  const height = getContentHeightOfPage("pageTerms");
  const termsWrpP = document.querySelector(
    `article[id='pageTerms'] div[data-class='wrapperP']`
  );
  const tipsWrpP = document.querySelector(
    `article[id='pageTips'] div[data-class='wrapperP']`
  );
  const contactWrpP = document.querySelector(
    `article[id='pageContact'] div[data-class='wrapperP']`
  );
  termsWrpP.style.height = `${height * 0.8}px`;
  tipsWrpP.style.height = `${height * 0.8}px`;
  contactWrpP.style.height = `${height * 0.8}px`;

  const pages = document.querySelectorAll(`article[data-class='page']`);
  pages.forEach((page) => {
    const width = getContentWidthOfPage(page.id);
    const wrapperPWidth = width * 0.6;
    const ts = page.querySelectorAll(`div[data-class='wrapperP']`);
    ts.forEach((t) => {
      if (wrapperPWidth > 600) {
        t.style.width = `500px`;
      } else if (wrapperPWidth < 350) {
        t.style.width = `330px`;
      } else {
        t.style.width = `${wrapperPWidth}px`;
      }
    });

    const wrapperH1Width = width * 0.8;
    const ts2 = page.querySelectorAll(`h2[data-class='wrapperH2']`);
    ts2.forEach((t) => {
      if (wrapperH1Width > 600) {
        t.style.width = `600px`;
      } else if (wrapperH1Width < 350) {
        t.style.width = `350px`;
      } else {
        t.style.width = `${wrapperH1Width}px`;
      }
      // const fs = getScaledValue(80);
      // // const fs = getFontSizeByTarget(t.textContent.length, t);
      // t.style.fontSize = `${fs}px`;
    });
  });
};
