"use strict";

// hover/color/shadowだけtailwind使用
// jsで実現しようとするとコードがかなり長くなる
//
// hoverとcolorはセットで使用するとうまく機能する
// >styleで指定した色はhoverで変更できない
export function execSetClassToAll() {
  document
    .querySelectorAll("li[data-class='naviList']")
    .forEach((t) => t.classList.add("hover:text-neutral-600"));
}
