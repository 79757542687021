"use strict";
import {
  BIKE_SCALE,
  GEOMETRY_GRID_COLOR,
  GEOMETRY_GRID_WEIGHT,
  GEO_SIZE,
  GRID_SPACE,
} from "./_const";
import { getDrawColor } from "./bike";

export const HIGH_LIGHT_COLOR = "rgba(200,200,0,0.5)";
export const DRAW_COLOR_COMPARE = [
  "rgba(255,100,153,1)",
  "rgba(130,140,251,1)",
  "rgba(60,210,100,1)",
  "rgba(255,127,0,1)",
  "rgba(0,203,203,1)",
  "rgba(152,203,0,1)",
  "rgba(216,101,255,1)",
];
export const DRAW_COLOR_SIZE = "rgba(150,170,170,1)";
const TIRE_SPEC = {
  tireColor: "rgba(120,120,100,1)",
  rimColor: "rgba(200,200,200,1)",
  rimHeight: 20,
};

export function isVisiblePartsValue(className) {
  if (!(className in DRAW_PARTS_VALUE)) {
    return false;
  }
  return DRAW_PARTS_VALUE[className].visible;
}

const valSize = {
  width: GRID_SPACE * 1.6,
  height: GRID_SPACE,
  padding: 8,
};

export const DRAW_PARTS_VALUE = {
  width: valSize.width,
  height: valSize.height,
  bottomBracket: {
    visible: false,
    getLineWidth: function ({ parts, bikeDraw }) {
      const BB_LINE_WIDTH = 2; // 実寸
      return BB_LINE_WIDTH;
    },
    getStrokeColor: function (parts) {
      return getDrawColor();
    },
  },
  headTubeAngle: {
    visible: true,
    getLineWidth: function ({ parts, bikeDraw }) {
      return GEOMETRY_GRID_WEIGHT;
    },
    getStrokeColor: function (parts) {
      return GEOMETRY_GRID_COLOR;
    },
  },
  seatTubeAngle: {
    visible: true,
    getLineWidth: function ({ parts, bikeDraw }) {
      return GEOMETRY_GRID_WEIGHT;
    },
    getStrokeColor: function (parts) {
      return GEOMETRY_GRID_COLOR;
    },
  },
  tireSizeFront: {
    visible: false,
    getLineWidth: function ({ parts, bikeDraw }) {
      return (parts.scl.dia - bikeDraw.arc.rimSizeRear.scl.dia) / 2;
    },
    getStrokeColor: function (parts) {
      return TIRE_SPEC.tireColor;
    },
  },
  tireSizeRear: {
    visible: true,
    getLineWidth: function ({ parts, bikeDraw }) {
      return (parts.scl.dia - bikeDraw.arc.rimSizeRear.scl.dia) / 2;
    },
    getStrokeColor: function (parts) {
      return TIRE_SPEC.tireColor;
    },
  },
  rimSizeFront: {
    visible: false,
    getLineWidth: function ({ parts, bikeDraw }) {
      return TIRE_SPEC.rimHeight * BIKE_SCALE;
    },
    getStrokeColor: function (parts) {
      return TIRE_SPEC.rimColor;
    },
  },
  rimSizeRear: {
    visible: true,
    getLineWidth: function ({ parts, bikeDraw }) {
      return TIRE_SPEC.rimHeight * BIKE_SCALE;
    },
    getStrokeColor: function (parts) {
      return TIRE_SPEC.rimColor;
    },
  },
  chainStay: {
    visible: true,
  },
  downTube: {
    visible: false,
  },
  forkCurve: {
    visible: false,
  },
  forkStraight: {
    visible: true,
  },
  headSetBottom: {
    visible: false,
  },
  headTube: {
    visible: true,
  },
  rearEnd: {
    visible: false,
  },
  seatStay: {
    visible: false,
  },
  seatTubeCtoC: {
    visible: true,
  },
  seatTubeCtoT: {
    visible: true,
  },

  topTube: {
    visible: true,
  },

  bbDrop: {
    visible: true,
  },
  bbHeight: {
    visible: true,
  },
  effectiveTopTube: {
    visible: true,
  },
  forkRake: {
    visible: true,
  },
  frontCenter: {
    visible: true,
  },
  reach: {
    visible: true,
  },
  stack: {
    visible: true,
  },
  standOver: {
    visible: true,
  },
  wheelBase: {
    visible: true,
  },
};
