/* eslint-disable spaced-comment */
"use strict";

// 1px = 1mm
// 大きすぎるので調整する
export const BIKE_SCALE = 0.4;
export const GRID_SPACE = 100 * BIKE_SCALE;

export const BIKE_BASE_Y_BY_GRID_SPACE = GRID_SPACE * 1.5;
export const BIKE_BASE_X_BY_GRID_SPACE = GRID_SPACE * 10.5;

//scaleはGEO_SIZEとwindowのwidthの比率
//scaleByBlock：displayとtable縦並びの場合
//scaleOfWidthByFlex : displayとtable横並びの場合
//widthの0.1倍が余白
//resizeStep:リサイズイベントのトリガーとなるwindowリサイズの変化量。1000で1px、100で10px
// resizeDecimal:リサイズ時のmathRoundSetDecimalPlaceで使用する小数点の位置、resizeStepと合わせて使用するので1pxでリサイズする
export const BORDER_X2 = 2; // border*2 = 2px
export const GEO_SIZE = {
  width: GRID_SPACE * 21 + BORDER_X2,
  height: GRID_SPACE * 12 + BORDER_X2,

  scaleOfWidthByBlock: 1.01,
  scaleOfWidthByFlex: 2.05,
  scaleOfWidthByFull: 1.2,

  scaleOfHeightByBlock: 2.05,
  scaleOfHeightByFlex: 1.1,
  scaleOfHeightByFull: 1.2,

  resizeStep: 1000,
  resizeDecimal: 3,
  adjustResizeHeightValue: 1,
};

export const NAVI_BLOCK = {
  WIDTH: 200,
};

export const ROUND_OF_HIGHLIGHT = 4;

export const FRAME_GRID_WEIGHT = 2;
export const GEOMETRY_GRID_WEIGHT = 2; // 奇数で設定すると表示がぼやけたり色がおかしくなる
export const GEOMETRY_GRID_COLOR = "rgba(100,100,100,1)";
export const FONT_COLOR = "rgb(153 143 143)";
export const FONT_COLOR_GEOMETRY = {
  calculated: "text-sky-400",
  referenced: "text-rose-400",
};

export const ZINDEX_WITH_DISP = {
  TIRE: 1,
  FRAME: 2,
  GRID: 3,
  LINE: 4,
  // 5-9はhighlightで使う
  VALUE: 10,
  ICON: 11,
};

export const ZINDEX = {
  TH: 1,
  TR_SIZE: 2,
  ICON_SCROLL: 3,
  OVERLAY: 4,
  SIDE_MENU: 5,
  LOWER_MENU: 5,
  NAVI_BY_BLOCK: 6,
  WRP_FULL_IMG: 7,
  SHIELD: 8,
  ICON_LOADER: 9,
};

// export const NAMES = {
//   cinelli: "Cinelli",
//   cinellimash: "Cinelli x MASH",
//   vigorelliTrackSteel: "Vigorelli Track Steel",
//   vigorelliShark: "Vigorelli Shark",
//   vigorelliAlu: "Vigorelli Alu",
//   tutto: "Tutto",
//   tuttoPlus: "Tutto Plus",
//   gazzetta: "Gazzetta",
//   sscx: "SSCX",
//   mash: "MASH",
//   mashsteel: "MASH STEEL",
//   mashac: "MASH AC",
//   work: "WORK",
//   bolt: "BOLT",
//   histogram: "HISTOGRAM",
//   parallax: "PARALLAX",
//   affinity: "AFFINITY",
//   lopro: "LO PRO",
//   metropolitan: "METROPOLITAN",
//   dosnoventa: "Dosnoventa",
//   losangels: "Los Angels",
//   detroit: "Detroit",
//   barcelona: "barcelona",
//   tokyo: "Tokyo",
//   fuji: "Fuji",
//   trackArcv: "TRACK ARCV",
//   surly: "SURLY",
//   crosscheck: "CROSSCHECK",
//   steamroller: "STEAMROLLER",
//   veloOrange: "VeloORANGE",
//   neutrino: "Neutrino",
//   masi: "MASI",
//   fennec: "Fennec",
// };
