"use strict";
import {
  BORDER_X2,
  FONT_COLOR,
  GEO_SIZE,
  ZINDEX,
  ZINDEX_WITH_DISP,
} from "./_const";
import { DRAW_PARTS_VALUE } from "./_constDrawPartsValue";
import { BTN_STYLE, WRAPPER_TITLE_STYLE, WRP_BTN_ICON_STYLE } from "./display";
import { LOWER_MENU } from "./lowerMenu";
import { setClassByBorder, setClassByHoverLight } from "./sizeTable";

export function execSetStyleToAll() {
  document.getElementById("naviBlock").style.zIndex = ZINDEX.NAVI_BY_BLOCK;
  document.getElementById("wrapperValueLeft").style.zIndex =
    ZINDEX_WITH_DISP.VALUE;
  document.getElementById("wrapperValueRight").style.zIndex =
    ZINDEX_WITH_DISP.VALUE;
  document.getElementById("wrapperImgsByYear").style.zIndex =
    ZINDEX.WRP_FULL_IMG;
  setClassByHoverLight(document.getElementById("wrpImgDisp"));
  // document.querySelectorAll(`div[id='wrapperBtnIcon'] svg`).forEach((t) => {
  //   t.classList.add("h-10");
  //   t.classList.add("w-10");
  // });

  document.querySelectorAll(`div[id='wrapperBtnIcon'] button`).forEach((t) => {
    t.classList.add("grid");
    t.classList.add("justify-items-center");
    t.classList.add("content-center");

    t.classList.add("text-neutral-200");
    t.classList.add("stroke-neutral-200");
    t.classList.add("hover:stroke-neutral-800");
    t.classList.add("hover:text-neutral-800");

    t.style.width = `${LOWER_MENU.HEIGHT_BASE}px`;
    t.style.height = `${LOWER_MENU.HEIGHT_BASE}px`;
    t.style.fontSize = `${14}px`;
    t.firstElementChild.style.with = `${30}px`;
    t.firstElementChild.style.height = `${30}px`;
  });

  document
    .querySelectorAll(
      "div[id='wrapperValueLeft'] button,div[id='wrapperValueRight'] button"
    )
    .forEach((t) => {
      setClassByHoverLight(t);
      setClassByBorder(t);
      t.style.color = FONT_COLOR;
      t.classList.add("place-items-start");
      t.classList.add("px-1");
      t.classList.add("mb-[6px]");
      t.classList.add("leading-none");
      t.classList.add("bg-white");
      t.style.zIndex = ZINDEX_WITH_DISP.VALUE;
      t.style.width = `${DRAW_PARTS_VALUE.width}px`;
      t.style.height = `${DRAW_PARTS_VALUE.height}px`;
    });
  document
    .querySelectorAll(
      "nav[id='naviBlock'] li[data-class='naviList'],[id='listLang']"
    )
    .forEach((t) => {
      setClassByHoverLight(t);
      t.classList.add("content-center");
      // t.classList.add("mt-2");
      t.classList.add("ml-2");
      t.style.color = FONT_COLOR;
      t.style.height = "40px";
    });

  const ids = [
    "bikeName",
    "btnWordingToEng",
    "btnWordingToJpn",
    "btnWordingToEngNavi",
    "btnWordingToJpnNavi",
    "contentP",
    "copyRight",
  ];
  ids.forEach((id) => (document.getElementById(id).style.color = FONT_COLOR));
  const wordingIds = [
    "top1",
    "top2",
    "top4",
    "top5",
    "tips1",
    "tips2",
    "term1",
    "term2",
    "contact1",
    "contact2",
  ];
  wordingIds.forEach(
    (id) =>
      (document.querySelector(`[data-wording-id='${id}']`).style.color =
        FONT_COLOR)
  );

  document
    .querySelectorAll(
      "div[id='wrapperValueLeft'] button,div[id='wrapperValueRight'] button"
    )
    .forEach((btn) => {
      btn.firstElementChild.style.color = FONT_COLOR;
    });
}
