"use strict";

import { getYears, replaceName } from "./_common";
import { FONT_COLOR, NAMES, ZINDEX_WITH_DISP } from "./_const";
import { setActiveClass } from "./display";
import { COL_COUNT, getColCount, getThImg } from "./sizeTable";
import { getWordingName } from "./wording";

export function removeSizePagination() {
  const thImgs = document.querySelectorAll(
    `th[data-class='img'][data-compare='false']`
  );
  thImgs.forEach((thImg) => {
    const btn = document.querySelector(
      `button[data-class='positionSize'][data-col-index='${thImg.dataset.colIndex}']`
    );
    if (btn) btn.remove();
  });
}

export function createSizePagination(values) {
  // *textContentとwidthはtab切替時に設定する
  values.forEach((val) => {
    const btn = document.createElement("button");
    btn.dataset.class = "positionSize";
    btn.dataset.colIndex = val.info.colIndex;
    btn.style.zIndex = ZINDEX_WITH_DISP.VALUE;
    btn.classList.add("leading-none");
    // btn.classList.add("h-8");
    btn.classList.add("cursor-pointer");
    btn.classList.add("m-1");
    btn.classList.add("border-solid");
    btn.classList.add("border-neutral-400");
    btn.classList.add("bg-white");
    btn.type = "button";
    btn.style.color = FONT_COLOR;
    document.getElementById("wrpPositionSize").appendChild(btn);
  });
}

export function initSizePagination() {
  const btns = document.querySelectorAll("button[data-class='positionSize']");
  btns.forEach((t) => {
    setActiveClass(t, "remove", "add");
    t.style.color = FONT_COLOR;
    t.style.display = "none";
  });
}

export function setSizePagination() {
  const isCompare = document.getElementById("display").dataset.compareDisp;
  const thImgs = getThImgs();
  if (thImgs.length === 0) return;

  for (let i = 0; i < thImgs.length; i++) {
    const thImg = thImgs[i];
    const posiSize = document.querySelector(
      `button[data-class='positionSize'][data-col-index='${thImg.dataset.colIndex}']`
    );
    if (!posiSize) continue;
    posiSize.style.display = "";
    if (isCompare === "true") {
      const years = getYears(thImg.dataset.years);
      posiSize.textContent = `${getWordingName(thImg.dataset.mdl)} ${years} ${
        thImg.dataset.size
      }`;
      posiSize.style.width = "128px";
      posiSize.style.height = "40px";
    } else {
      posiSize.textContent = thImg.dataset.size;
      posiSize.style.width = "56px";
      posiSize.style.height = "40px";
    }
    if (thImg.dataset.active === "true") {
      setActiveClass(posiSize, "add", "remove");
      posiSize.style.color = "";
    }
  }

  function getThImgs() {
    if (isCompare === "false") {
      const thName = document.querySelector(
        `th[data-class='name'][data-col-index='header']`
      );
      return document.querySelectorAll(
        `th[data-class='img'][data-mkr='${thName.dataset.mkr}'][data-mdl='${thName.dataset.mdl}'][data-years='${thName.dataset.year}'][data-compare='false']`
      );
    } else {
      return document.querySelectorAll(
        `th[data-class='img'][data-compare='true']`
      );
    }
  }
}
