"use strict";

import { ZINDEX } from "./_const";
import { setStyleToNaviIconByOpen } from "./navi";
import { getActivePage } from "./page";

export function onClickOverlay(event) {
  const page = getActivePage();
  const isNaviBlock =
    document.getElementById("naviBlock").style.display === ""
      ? "true"
      : "false";

  // lowerのoverlayがクリックされた場合
  // if (page.id !== "pageCompare") {
  if (isNaviBlock === "false") {
    return;
  } else if (isNaviBlock === "true") {
    setStyleToNaviIconByOpen("init");
    return;
  }
  // }

  const overlay = document.getElementById("overlay");
  document.getElementById("sideMenu").style.display = "none";
  document.getElementById("btnShowSideMenu").style.zIndex = "";
  document.getElementById("naviBlock").style.display = "none";
  overlay.style.display = "none";
  overlay.style.zIndex = ZINDEX.OVERLAY;
}
